import { useEffect, useState } from 'react';
import { Collection } from 'models/Collection';
import { useForm } from 'react-hook-form';
import { CollectionFormSkeleton } from './CollectionFormSkeleton';
import { useSaveCollection, Variables } from 'hooks/useSaveCollection';
import ValidationMessage from 'elements/ValidationMessage';
import { closeSlideOver } from 'services/SlideOverService';
import EmojiPicker from 'emoji-picker-react';
import ClickAwayListener from 'react-click-away-listener';
import { useSlideOver } from 'hooks/useSlideOver';
import classNames from 'classnames';

interface Props {
  collection?: Collection;
  isLoading?: boolean;
  onSuccess?: (collection: Collection) => void;
}

export const CollectionForm: React.FC<Props> = ({
  collection,
  isLoading = false,
  onSuccess,
}) => {
  const { isOpen } = useSlideOver();
  // TODO: Refactor this to remove this useless useState and leverage React Hook Form state for `emoji` instead
  const [selectedEmoji, setSelectedEmoji] = useState(collection?.emoji);
  const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onSubmit' });

  const onCollectionCreated = (collection: Collection) => {
    if (onSuccess) {
      onSuccess(collection);
    }
    reset();
    setSelectedEmoji(null);
  };

  const saveCollectionMutation = useSaveCollection({
    onSuccess: onCollectionCreated,
  });

  const onSubmit = async (data) => {
    const collection = {
      ...data,
      ...(selectedEmoji ? { emoji: selectedEmoji } : {}),
    };

    const variables: Variables = {
      collection,
    };

    saveCollectionMutation.mutate(variables);
  };

  const categoryFieldName = 'name';
  const categoryFieldValidation = { required: true, minLength: 2 };

  const onEscapePress = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setEmojiPickerVisible(false);
    }
  };

  const onEmojiSelection = (emoji: string) => {
    setSelectedEmoji(emoji);
    setEmojiPickerVisible(false);
  };

  const handleEmojiButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (selectedEmoji) {
      setSelectedEmoji(null);
      setEmojiPickerVisible(false);
    } else {
      setEmojiPickerVisible(true);
    }
  };

  const onClickAway = () => {
    setEmojiPickerVisible(false);
  };

  useEffect(() => {
    if (collection) {
      Object.entries(collection).forEach(([key, value]) => {
        setValue(key, value);
      });
      setSelectedEmoji(collection?.emoji || null);
    } else {
      setSelectedEmoji(null);
      setEmojiPickerVisible(false);
    }
  }, [collection]);

  useEffect(() => {
    if (isOpen) {
      setFocus(categoryFieldName);
    }
  }, [isOpen, setFocus]);

  useEffect(() => {
    const handleKeydown = (event) => onEscapePress(event);
    document.addEventListener('keydown', handleKeydown);

    return () => document.removeEventListener('keydown', handleKeydown);
  }, []);

  return isLoading || saveCollectionMutation.isLoading ? (
    <CollectionFormSkeleton />
  ) : (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.collectionForm}>
        <div className="sm:col-span-1 pt-3 w-full flex flex-col items-center">
          <div className="mb-3 w-full flex flex-col items-center">
            <button
              type="button"
              className={styles.ellipse(!!selectedEmoji)}
              onClick={() => setEmojiPickerVisible(!isEmojiPickerVisible)}
            >
              {selectedEmoji ? (
                <span>{selectedEmoji}</span>
              ) : (
                <span className={styles.hashTagIcon}>#</span>
              )}
            </button>
            <button
              type="button"
              className={styles.addEmojiButton}
              onClick={handleEmojiButtonClick}
            >
              {selectedEmoji ? 'Remove Emoji' : 'Add an Emoji'}
            </button>
          </div>

          {isEmojiPickerVisible && (
            <ClickAwayListener onClickAway={onClickAway}>
              <div className="flex justify-center items-center absolute z-10 mt-20">
                <EmojiPicker
                  onEmojiClick={(emojiObject) =>
                    onEmojiSelection(emojiObject.emoji)
                  }
                />
              </div>
            </ClickAwayListener>
          )}
        </div>
        <div className={styles.collectionContainer}>
          <div className="sm:col-span-3">
            <label htmlFor={'category-name'} className={styles.collectionName}>
              Collection name
            </label>
            <div className="mt-1">
              <input
                type="text"
                name={categoryFieldName}
                id="category-name"
                autoComplete="off"
                data-1p-ignore // Ignore 1Password
                className={`${styles.input.default} ${
                  errors[categoryFieldName]
                    ? styles.input.error.yes
                    : styles.input.error.no
                }`}
                {...register(categoryFieldName, categoryFieldValidation)}
              />
            </div>
            <div className="flex flex-col items-end mr-2">
              {errors?.[categoryFieldName] &&
                errors[categoryFieldName].type === 'required' && (
                  <ValidationMessage>
                    Please enter a Collection name
                  </ValidationMessage>
                )}
              {errors?.[categoryFieldName] &&
                errors[categoryFieldName].type === 'minLength' && (
                  <ValidationMessage>
                    At least 2 characters required
                  </ValidationMessage>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.collectionFormFooter}>
        <button
          type="button"
          className={styles.cancelButton}
          onClick={closeSlideOver}
        >
          Cancel
        </button>
        <button type="submit" className={styles.addButton}>
          {collection ? 'Save' : 'Create'}
        </button>
      </div>
    </form>
  );
};

const styles = {
  collectionForm: 'border-gray-200 px-6 pb-6',
  emoji: 'flex justify-center mt-2 block text-sm font-medium text-gray-700',
  input: {
    default: classNames(
      'block w-full text-sm border-[#F4F4F4] border-2 bg-[#F7FAFF] rounded-xl outline-none',
      'focus:ring-0'
    ),
    error: {
      yes: 'focus:border-[#E20035]',
      no: 'focus:border-[#161E3A]',
    },
  },
  ellipse: (isSelected: boolean) =>
    classNames(
      'mt-2 flex items-center justify-center text-3xl border-2 border-dashed hover:bg-zinc-100 active:bg-neutral-200 rounded-full p-2 w-20 h-20',
      'active:border-blue-700  active:text-blue-700',
      isSelected && 'border-[#161E3A]'
    ),
  hashTagIcon: 'flex items-center justify-center text-3xl text-gray-500',
  addEmojiButton: 'text-sm font-medium mt-2 text-[#6C727E]',
  ellipseWithEmoji:
    'mt-2 flex items-center justify-center text-3xl border-2 border-dashed border-black rounded-full p-2 w-16 h-16',
  cursorPointer:
    'w-82 h-82 flex justify-center items-center cursor-pointer rounded-full',
  collectionContainer: 'flex mt-4 grid grid-cols-1 gap-y-6 gap-x-4 w-full',
  collectionName: 'block text-sm font-normal text-gray-700',
  collectionInput:
    'shadow-sm block w-full sm:text-sm border-gray-300 bg-slate-50 rounded-md outline-none focus:border-gray-800 focus:ring-1 focus:ring-gray-800 text-black',
  cancelButton:
    'rounded-3xl border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 active:bg-neutral-200 focus:outline-none',
  addButton:
    'ml-4 inline-flex justify-center rounded-3xl border border-transparent bg-mf-blue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-mf-dark-blue active:bg-blue-950 focus:outline-none',
  collectionFormFooter: 'flex shrink-0 justify-end px-4',
};

export default CollectionForm;
