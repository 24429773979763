import { Sidebar, SidebarBody, SidebarHeader, SidebarSection } from './Sidebar';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import IconButton from 'public/icons/IconButton';
import { openCollectionSlideOver } from 'services/SlideOverService';
import CollectionList from 'elements/Collection/CollectionList';
import { closeSidebar } from 'xstate/services/SidebarService';

const CollectionSidebar = () => {
  const onCreateClick = () => {
    closeSidebar();
    openCollectionSlideOver();
  };

  return (
    <Sidebar>
      <SidebarHeader className="border-none pb-0">
        <div className={styles.title.container}>
          <span className={styles.title.text}>Collections</span>
          <IconButton icon={faPlus} onClick={onCreateClick} />
        </div>
      </SidebarHeader>

      <SidebarBody className="pt-0">
        <SidebarSection>
          <CollectionList
            onCollectionSelect={closeSidebar}
            onCollectionEdit={closeSidebar}
            onCollectionDelete={closeSidebar}
          />
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
};

export default CollectionSidebar;

const styles = {
  title: {
    container: classNames('flex-none', 'flex items-center pt-0 pb-4'),
    text: 'flex-1 font-bold text-lg text-slate-800 pl-1',
  },
};
