import { interpret } from 'xstate';
import { notificationMachine } from 'machines/NotificationMachine';
import { Notification, NotificationKind } from 'models/Notification';
import { v4 as uuidv4 } from 'uuid';

export const NotificationService = interpret(notificationMachine);
NotificationService.start();

export enum NotificationType {
  BOX_CREATED,
  BOX_UPDATED,
  BOX_DELETED,
  COLLECTION_CREATED,
  COLLECTION_UPDATED,
  COLLECTION_DELETED,
  DROP_CREATED,
  DROP_UPDATED,
  DROP_DELETED,
}

export const buildNotification = (
  type: NotificationType,
  kind: NotificationKind = NotificationKind.Success
): Notification => {
  switch (type) {
    case NotificationType.BOX_CREATED:
      return kind === NotificationKind.Error
        ? {
            id: uuidv4(),
            kind: NotificationKind.Error,
            title: 'An error occurred',
            visible: true,
          }
        : {
            id: uuidv4(),
            kind: NotificationKind.Success,
            title: 'Memory Box Created',
            visible: true,
          };
    case NotificationType.BOX_UPDATED:
      return kind === NotificationKind.Error
        ? {
            id: uuidv4(),
            kind: NotificationKind.Error,
            title: 'An error occurred',
            visible: true,
          }
        : {
            id: uuidv4(),
            kind: NotificationKind.Success,
            title: 'Memory Box Updated',
            visible: true,
          };
    case NotificationType.BOX_DELETED:
      return kind === NotificationKind.Error
        ? {
            id: uuidv4(),
            kind: NotificationKind.Error,
            title: 'An error occurred',
            visible: true,
          }
        : {
            id: uuidv4(),
            kind: NotificationKind.Success,
            title: 'Memory Box Deleted',
            visible: true,
          };
    case NotificationType.COLLECTION_CREATED:
      return kind === NotificationKind.Error
        ? {
            id: uuidv4(),
            kind: NotificationKind.Error,
            title: 'An error occurred',
            visible: true,
          }
        : {
            id: uuidv4(),
            kind: NotificationKind.Success,
            title: 'Collection Created',
            visible: true,
          };
    case NotificationType.COLLECTION_UPDATED:
      return kind === NotificationKind.Error
        ? {
            id: uuidv4(),
            kind: NotificationKind.Error,
            title: 'An error occurred',
            visible: true,
          }
        : {
            id: uuidv4(),
            kind: NotificationKind.Success,
            title: 'Collection Updated',
            visible: true,
          };
    case NotificationType.COLLECTION_DELETED:
      return kind === NotificationKind.Error
        ? {
            id: uuidv4(),
            kind: NotificationKind.Error,
            title: 'An error occurred',
            visible: true,
          }
        : {
            id: uuidv4(),
            kind: NotificationKind.Success,
            title: 'Collection Deleted',
            visible: true,
          };
    case NotificationType.DROP_CREATED:
      return kind === NotificationKind.Error
        ? {
            id: uuidv4(),
            kind: NotificationKind.Error,
            title: 'An error occurred',
            visible: true,
          }
        : {
            id: uuidv4(),
            kind: NotificationKind.Success,
            title: 'Memo Created',
            visible: true,
          };
    case NotificationType.DROP_UPDATED:
      return kind === NotificationKind.Error
        ? {
            id: uuidv4(),
            kind: NotificationKind.Error,
            title: 'An error occurred',
            visible: true,
          }
        : {
            id: uuidv4(),
            kind: NotificationKind.Success,
            title: 'Memo Updated',
            visible: true,
          };
    case NotificationType.DROP_DELETED:
      return kind === NotificationKind.Error
        ? {
            id: uuidv4(),
            kind: NotificationKind.Error,
            title: 'An error occurred',
            visible: true,
          }
        : {
            id: uuidv4(),
            kind: NotificationKind.Success,
            title: 'Memo Deleted',
            visible: true,
          };
  }
};

// Notify the use with a notification
// If autoHide is set to true, the notification will disappear after `timeout` milliseconds
export const notify = (
  notificationType: NotificationType,
  kind: NotificationKind = NotificationKind.Success,
  autoHide = true,
  timeoutInMs = 1500
): void => {
  const notification = buildNotification(notificationType, kind);
  NotificationService.send({ type: 'NOTIFICATION.SHOW', notification });
  if (autoHide) {
    setTimeout(() => {
      denotify(notification);
    }, timeoutInMs);
  }
};

export const denotify = (notification: Notification): void => {
  NotificationService.send({
    type: 'NOTIFICATION.HIDE',
    notification: notification,
  });
};
