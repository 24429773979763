import { ReactElement } from 'react';
import { closeSlideOver } from 'services/SlideOverService';
import { BoxSlideOverPayload } from 'machines/SlideOverMachine';
import MemoryBoxForm from 'elements/MemoryBox/MemoryBoxForm';
import { useSlideOver } from 'hooks/useSlideOver';

const BoxSlideOver = (): ReactElement => {
  const { payload } = useSlideOver() as { payload: BoxSlideOverPayload };

  return <MemoryBoxForm onSuccess={closeSlideOver} box={payload.box} />;
};

export default BoxSlideOver;
