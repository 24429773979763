import { DialogPanel } from '@headlessui/react';
import React, { ReactElement } from 'react';
import Login from 'templates/Login';

const LoginModal = (): ReactElement => {
  return (
    <div className={styles.outer}>
      <div className={styles.inner}>
        <DialogPanel transition className={styles.panel}>
          <Login />
        </DialogPanel>
      </div>
    </div>
  );
};

export default LoginModal;

const styles = {
  outer: 'fixed inset-0 z-10 w-screen overflow-y-auto',
  inner:
    'flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0',
  panel:
    'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95',
};
