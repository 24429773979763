import { isBrowser } from 'utils/BrowserUtils';

const storeKeys = {
  // The box `id` of the last memory box that was selected by the user
  lastSelectedBoxId: 'last-selected-box-id',
} as const;

export type StoreKeysType = (typeof storeKeys)[keyof typeof storeKeys];

class Store {
  setItem<T>(key: StoreKeysType, value: T): void {
    if (isBrowser()) {
      try {
        const jsonValue = JSON.stringify(value);
        localStorage.setItem(key, jsonValue);
      } catch (e) {
        console.error(`Error setting item ${key} in localStorage`, e);
      }
    } else {
      console.warn(`localStorage is not available. Cannot set item ${key}.`);
    }
  }

  getItem<T>(key: StoreKeysType): T | null {
    if (isBrowser()) {
      try {
        const jsonValue = localStorage.getItem(key);
        return jsonValue != null ? (JSON.parse(jsonValue) as T) : null;
      } catch (e) {
        console.error(`Error getting item ${key} from localStorage`, e);
        return null;
      }
    } else {
      console.warn(`localStorage is not available. Cannot get item ${key}.`);
    }
  }

  removeItem(key: StoreKeysType): void {
    if (isBrowser()) {
      try {
        localStorage.removeItem(key);
      } catch (e) {
        console.error(`Error removing item ${key} from localStorage`, e);
      }
    } else {
      console.warn(`localStorage is not available. Cannot remove item ${key}.`);
    }
  }

  clear(): void {
    if (isBrowser()) {
      try {
        Object.values(storeKeys).forEach((key) => localStorage.removeItem(key));
      } catch (error) {
        console.error('Error clearing app storage from localStorage', error);
      }
    } else {
      console.warn(`localStorage is not available. Cannot clear.`);
    }
  }
}

let store: Store;

function getStore(): Store {
  if (!store) {
    store = new Store();
  }

  return store;
}

export { getStore, storeKeys };
