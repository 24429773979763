import MemoryBoxList from 'elements/MemoryBox/MemoryBoxList';
import ProTrialCard from 'elements/ProTrialCard';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import IconButton from 'public/icons/IconButton';
import { openBoxSlideOver } from 'services/SlideOverService';
import { closeSidebar } from 'xstate/services/SidebarService';
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarSection,
} from './Sidebar';
import UserButton from 'elements/UserButton';
import { useAuthentication } from 'hooks/useAuthentication';
import { useProStatus } from 'networking/revenueCat/hooks/useProStatus';

const BoxSidebar = () => {
  const { authenticatedUser } = useAuthentication();
  const { isPro } = useProStatus();
  const onCreateClick = () => {
    closeSidebar();
    openBoxSlideOver();
  };

  return (
    <Sidebar>
      <SidebarHeader className="border-none pb-0">
        <div className={styles.title.container}>
          <span className={styles.title.text}>Memory Boxes</span>
          <IconButton icon={faPlus} onClick={onCreateClick} />
        </div>
      </SidebarHeader>

      <SidebarBody className="pt-0">
        <SidebarSection>
          <MemoryBoxList
            onBoxSelect={closeSidebar}
            onBoxEdit={closeSidebar}
            onBoxDelete={closeSidebar}
          />
        </SidebarSection>

        {!isPro && (
          <SidebarSection>
            <ProTrialCard />
          </SidebarSection>
        )}
      </SidebarBody>

      <SidebarFooter>
        <UserButton user={authenticatedUser} isPro={isPro} direction="up" />
      </SidebarFooter>
    </Sidebar>
  );
};

export default BoxSidebar;

const styles = {
  title: {
    container: classNames('flex-none', 'flex items-center pt-0 pb-4'),
    text: 'flex-1 font-bold text-lg text-slate-800 pl-1',
  },
};
