import { Box } from 'models/Box';
import { AccessLevel } from 'models/AccessLevel';
import { createMachine, assign } from 'xstate';
import { Collection } from 'models/Collection';
import { getBoxes } from 'networking/mindfeed/services/box.service';
import { queryClient, QueryKey } from 'services/queryClient';
import { getStore, storeKeys } from 'services/Store';

// State

export enum StateValue {
  CheckingSelection = 'CHECKING_SELECTION',
  Unselected = 'UNSELECTED',
  BoxSelected = 'BOX_SELECTED',
}

const CHECKING_SELECTION = StateValue.CheckingSelection;
const UNSELECTED = StateValue.Unselected;
const BOX_SELECTED = StateValue.BoxSelected;

export type BoxMachineState =
  | {
      value: StateValue.CheckingSelection;
      context: BoxMachineContext;
    }
  | {
      value: StateValue.Unselected;
      context: BoxMachineContext;
    }
  | {
      value: StateValue.BoxSelected;
      context: BoxMachineContext & {
        selectedBox: Box;
        selectedCollection: Collection;
      };
    };

// Events

export enum EventType {
  RequestToCheckSelection = 'REQUEST_TO_CHECK_SELECTION',
  RequestToSelectBox = 'REQUEST_TO_SELECT_BOX',
  RequestToSelectCollection = 'REQUEST_TO_SELECT_COLLECTION',
  RequestToResetBox = 'REQUEST_TO_RESET_BOX',
  RequestToResetCollection = 'REQUEST_TO_RESET_COLLECTION',
}

type RequestToCheckSelectionEvent = {
  type: EventType.RequestToCheckSelection;
};

type RequestToSelectBoxEvent = {
  type: EventType.RequestToSelectBox;
  box?: Box;
};

type RequestToSelectCollectionEvent = {
  type: EventType.RequestToSelectCollection;
  collection: Collection;
};

type RequestToResetBoxEvent = {
  type: EventType.RequestToResetBox;
};

type RequestToResetCollectionEvent = {
  type: EventType.RequestToResetCollection;
};

export type BoxMachineEvent =
  | RequestToCheckSelectionEvent
  | RequestToSelectBoxEvent
  | RequestToSelectCollectionEvent
  | RequestToResetBoxEvent
  | RequestToResetCollectionEvent;

// Context

export interface BoxMachineContext {
  selectedBox?: Box;
  selectedCollection?: Collection;
}

// State Machine

export const boxMachine = createMachine<
  BoxMachineContext,
  BoxMachineEvent,
  BoxMachineState
>(
  {
    id: 'box',
    context: {
      selectedBox: undefined,
      selectedCollection: undefined,
    },
    initial: CHECKING_SELECTION,
    predictableActionArguments: true,
    states: {
      // Checking Authentication
      // MMKV persisted state drives the Selection Status, hence we use this State
      // to check the Selection Status from MMKV
      [CHECKING_SELECTION]: {
        invoke: {
          src: 'checkSelection',
          onDone: {
            target: BOX_SELECTED,
            actions: ['setSelectedBox'],
          },
          onError: {
            target: UNSELECTED,
          },
        },
      },
      [UNSELECTED]: {
        exit: 'resetBox',
        on: {
          [EventType.RequestToCheckSelection]: {
            target: CHECKING_SELECTION,
          },
          [EventType.RequestToSelectBox]: {
            target: BOX_SELECTED,
            actions: ['setSelectedBox'],
          },
          [EventType.RequestToResetBox]: {
            target: UNSELECTED,
            actions: ['resetBox'],
          },
        },
      },
      [BOX_SELECTED]: {
        on: {
          [EventType.RequestToCheckSelection]: {
            target: CHECKING_SELECTION,
          },
          [EventType.RequestToSelectBox]: {
            target: BOX_SELECTED,
            actions: ['setSelectedBox'],
          },
          [EventType.RequestToSelectCollection]: {
            actions: ['setSelectedCollection'],
          },
          [EventType.RequestToResetBox]: {
            target: UNSELECTED,
            actions: ['resetBox'],
          },
          [EventType.RequestToResetCollection]: {
            target: BOX_SELECTED,
            actions: ['resetCollection'],
          },
        },
      },
    },
  },
  {
    services: {
      // Services
      checkSelection: async (_ctx, _event) => {
        const lastSelectedBoxId = getStore().getItem<string>(
          storeKeys.lastSelectedBoxId
        );

        try {
          const boxes: Box[] = await queryClient.fetchQuery(
            [QueryKey.BOXES],
            getBoxes
          );
          const lastSelectedBox = boxes.find(
            (box) => box.id === lastSelectedBoxId
          );

          if (lastSelectedBox) {
            return Promise.resolve({
              box: lastSelectedBox,
            });
          }

          const boxToSelect = boxes.find(
            (box) =>
              box.accessLevel === AccessLevel.Private && box.isDefault === true
          );
          if (boxToSelect) {
            return Promise.resolve({
              box: boxToSelect,
            });
          }

          throw new Error('Cannot find a default private box.');
        } catch (error) {
          Promise.reject(error);
        }
      },
    },
    actions: {
      resetBox: assign((_ctx: any, _event: any) => {
        // Remove last persistent selected box `id`
        getStore().removeItem(storeKeys.lastSelectedBoxId);

        return {
          selectedBox: undefined,
          selectedCollection: undefined,
        };
      }),

      resetCollection: assign({
        selectedCollection: (_ctx: any, _event: any) => undefined,
      }),

      setSelectedBox: assign((_ctx: any, event: any) => {
        // TODO: Need to UNDERSTAND why it is wrapped in `data` when coming
        // from `checkSelection` and not wrapped in `data` when coming from
        // requestToSelectBox()
        const box = event.box || event.data?.box;

        // Store last selected box `id` persistently
        if (box.id) {
          getStore().setItem<string>(storeKeys.lastSelectedBoxId, box.id);
        }

        return {
          selectedBox: box,
          selectedCollection: undefined,
        };
      }),

      setSelectedCollection: assign({
        // update the selected collection
        selectedCollection: (ctx: any, event: any) =>
          event.collection || event.data?.collection,
      }),
    },
  }
);
