import { useMutation, useQueryClient } from 'react-query';
import { QueryKey } from 'services/queryClient';
import { isContext } from 'utils/ContextUtils';
import type { Box } from 'models/Box';
import { deleteBox } from 'networking/mindfeed/services/box.service';
import { NotificationType, notify } from 'services/NotificationService';

type Context = { previousBoxes: Box[] };

const useDeleteBox = ({
  onSuccess,
  removeOnSuccess = false,
}: {
  onSuccess?: () => void;
  removeOnSuccess?: boolean;
}): ReturnType<typeof useMutation> => {
  const queryClient = useQueryClient();

  const onMutateCallback = async (box: Box) => {
    await queryClient.cancelQueries([QueryKey.BOXES]);

    const previousBoxes = queryClient.getQueryData<Box[]>([QueryKey.BOXES]);

    if (previousBoxes) {
      const updater = previousBoxes.filter((b) => {
        return b.id !== box.id;
      });

      queryClient.setQueryData<Box[]>([QueryKey.BOXES], updater);
    }

    return { previousBoxes };
  };

  const onSuccessCallback = async (_box: Box, _variables, _context) => {
    if (removeOnSuccess) {
      await queryClient.removeQueries([QueryKey.BOXES]);
    }
    if (onSuccess) {
      onSuccess();
    }

    notify(NotificationType.BOX_DELETED);
  };

  const onErrorCallback = (_err, _variables, context) => {
    if (isContext<Context>(context, 'previousBoxes')) {
      queryClient.setQueryData<Box[]>([QueryKey.BOXES], context.previousBoxes);
    }
  };

  const deleteBoxMutation = useMutation(deleteBox, {
    onMutate: onMutateCallback,
    onSuccess: onSuccessCallback,
    onError: onErrorCallback,
  });

  return deleteBoxMutation;
};

export default useDeleteBox;
