import { useQuery } from 'react-query';
import { CustomerInfo, getRevenueCatClient } from '../services/revenueCat';
import { useAuthentication } from 'hooks/useAuthentication';

const fetchCustomerInfo = async (userId: string): Promise<CustomerInfo> => {
  const customerInfo = await getRevenueCatClient().getCustomerInfo(userId);

  return customerInfo;
};

export const useSubscriptionInfo = () => {
  const { authenticatedUser } = useAuthentication();
  const cloudIdentityId = authenticatedUser?.cloudIdentityId;

  return useQuery<CustomerInfo, Error>(
    ['customerInfo', cloudIdentityId],
    () => fetchCustomerInfo(cloudIdentityId),
    {
      enabled: !!cloudIdentityId, // Fetch data only when appUserId is available
      staleTime: 1000 * 60 * 20, // Data will be considered fresh for 20 minutes
      cacheTime: 1000 * 60 * 60, // Unused data will be cached for 60 minutes
    }
  );
};
