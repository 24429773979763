import type { Drop } from 'models/Drop';
import { HTTPMethod } from 'utils/HTTPUtils';
import { authenticationHeaders } from './firebase';
import { mindfeed as config } from 'config/global';
import { Box } from 'models/Box';

export const DROPS_URI = `${config.baseUri}/api/drops`;

export type GetDropsResponse = Drop[];

export const getDrops = async (box?: Box): Promise<GetDropsResponse> => {
  const url = box ? `${config.baseUri}/api/boxes/${box.id}/drops` : DROPS_URI;

  return fetch(url, {
    method: HTTPMethod.GET,
    headers: await authenticationHeaders(),
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Unable to fetch drops: error ${response.status}`);
    }
    return response.json() as Promise<GetDropsResponse>;
  });
};

export const saveDrop = async (
  { id, ...restDropProperties }: Drop,
  box?: Box
): Promise<Drop> => {
  const body = JSON.stringify({
    id,
    ...(box && box.id ? { boxId: box.id } : {}), // Add boxId property only if box.id exists
    ...restDropProperties,
  });

  return fetch(DROPS_URI, {
    method: id ? HTTPMethod.PUT : HTTPMethod.POST,
    headers: await authenticationHeaders(),
    body,
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Unable to save drop: error ${response.status}`);
    }
    return response.json() as Promise<Drop>;
  });
};

export const deleteDrop = async (id: string): Promise<Drop> =>
  fetch(`${DROPS_URI}/${id}`, {
    method: HTTPMethod.DELETE,
    headers: await authenticationHeaders(),
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Unable to delete drop: error ${response.status}`);
    }
    return response.json() as Promise<Drop>;
  });
