import { useMutation, useQueryClient } from 'react-query';
import type { Collection } from 'models/Collection';
import { QueryKey } from 'services/queryClient';
import { saveCollection } from 'services/collection.service';
import { notify, NotificationType } from 'services/NotificationService';
import { isContext } from 'utils/ContextUtils';
import { useXBox } from 'xstate/hooks/useXBox';

type Context = {
  previousCollections: Collection[];
};

export type Variables = {
  collection: Collection;
};

export const useSaveCollection = ({
  onSuccess,
}: {
  onSuccess?: (data: Collection) => void;
} = {}) => {
  const queryClient = useQueryClient();
  const selectedBox = useXBox().getSelectedBox();

  const onMutateCallback = async (
    variables: Variables
  ): Promise<Context | undefined> => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries([QueryKey.COLLECTIONS, selectedBox?.id]);

    // Snapshot the previous value
    const previousCollections = queryClient.getQueryData<Collection[]>([
      QueryKey.COLLECTIONS,
      selectedBox?.id,
    ]);

    // Whether the collection already exists in the stack
    const itExists =
      previousCollections instanceof Array &&
      previousCollections.some((c) => c.id === variables.collection.id);

    // Optimistically update to the new value
    if (itExists) {
      queryClient.setQueryData<Collection[]>(
        [QueryKey.COLLECTIONS, selectedBox?.id],
        previousCollections.map((c) =>
          c.id !== variables.collection.id ? c : variables.collection
        )
      );
    } else {
      // We don't do anything because we don't have an ID to use as key
    }

    return { previousCollections } as Context;
  };

  const onSuccessCallback = async (
    collection: Collection,
    _variables: Variables,
    context: Context | undefined
  ) => {
    // Whether the collection already exists in the stack
    const itExists = context.previousCollections.some(
      (c) => c.id === collection.id
    );

    if (itExists) {
      // Update the recently updated collection
      queryClient.setQueryData<Collection[]>(
        [QueryKey.COLLECTIONS, selectedBox?.id],
        context.previousCollections.map((c) =>
          c.id !== collection.id ? c : collection
        )
      );

      // Notify User
      notify(NotificationType.COLLECTION_UPDATED);
    } else {
      // Add the newly created collection
      queryClient.setQueryData(
        [QueryKey.COLLECTIONS, selectedBox?.id],
        (data: Collection[]) => {
          return [...data, collection];
        }
      );

      // Notify User
      notify(NotificationType.COLLECTION_CREATED);
    }

    if (onSuccess) {
      onSuccess(collection);
    }
  };

  const onErrorCallback = (
    _err: any,
    _variables: Variables,
    context: Context | undefined
  ) => {
    // If the mutation fails, use the context returned from onMutate to roll back
    if (isContext<Context>(context, 'previousCollections')) {
      queryClient.setQueryData<Collection[]>(
        [QueryKey.COLLECTIONS, selectedBox?.id],
        context.previousCollections
      );
    }
  };

  const saveCollectionMutation = useMutation<
    Collection,
    any,
    Variables,
    Context
  >((variables) => saveCollection(variables.collection, selectedBox), {
    onMutate: onMutateCallback,
    onSuccess: onSuccessCallback,
    onError: onErrorCallback,
  });

  return saveCollectionMutation;
};
