import ContentLoader from 'react-content-loader';

export const CollectionSkeleton = (): JSX.Element => {
  return (
    <div className="bg-white overflow-hidden">
      <ContentLoader
        animate={true}
        foregroundColor={'#CED8F3'}
        width={'100%'}
        height={'100px'}
      >
        {/* First Line */}
        <rect x="0" y="0" width="75%" height="20px" rx="10px" />
        <rect x="80%" y="0" width="30px" height="20px" rx="10px" />
        {/* Second Line */}
        <rect x="0" y="30px" width="75%" height="20px" rx="10px" />
        <rect x="80%" y="30px" width="30px" height="20px" rx="10px" />
        {/* Third Line */}
        <rect x="0" y="60px" width="75%" height="20px" rx="10px" />
        <rect x="80%" y="60px" width="30px" height="20px" rx="10px" />
      </ContentLoader>
    </div>
  );
};
