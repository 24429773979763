import React, { FC, useState } from 'react';
import Avatar from 'elements/Avatar';
import { appRoutes } from 'config/appRoutes';
import ClickAwayListener from 'listeners/ClickAwayListener';
import { User } from 'models/User';
import { requestToLogout } from 'services/AuthService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleUp,
  faCrown,
  faRightFromBracket,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import Router from 'next/router';

type Direction = 'up' | 'down';

interface UserInfoProps {
  user: User;
  isPro?: boolean;
  direction?: Direction;
  className?: string;
}

const UserInfo: FC<UserInfoProps> = ({ user, isPro, className }) => (
  <div className={styles.userInfo.container(className)}>
    <div className={styles.userInfo.left}>
      <Avatar user={user} withGoldRing={isPro} />
    </div>

    <div className={styles.userInfo.right.container}>
      <span className={styles.userInfo.right.name} suppressHydrationWarning>
        {user?.getDisplayName() ?? 'Display Name'}
      </span>
      <span className={styles.userInfo.right.username} suppressHydrationWarning>
        {user?.getUsername() ?? '@username'}
      </span>
    </div>
  </div>
);

interface DropdownProps {
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  user: User;
  isPro?: boolean;
  direction?: Direction;
}

const Dropdown: FC<DropdownProps> = ({
  isOpen,
  setIsOpen,
  direction,
  user,
  isPro,
}) => {
  const navigateTo = (url: string) => {
    Router.push(url);
    setIsOpen(false);
  };

  return (
    isOpen && (
      <div className={styles.dropDown.container(direction)}>
        <UserInfo
          user={user}
          isPro={isPro}
          className={styles.dropDown.userInfo}
        />

        {/* Account */}
        <button
          className={styles.dropDown.btn.container}
          onClick={() => navigateTo(appRoutes.ACCOUNT)}
        >
          <div className={styles.dropDown.btn.icon.container}>
            <FontAwesomeIcon
              icon={faUser}
              className={styles.dropDown.btn.icon.gray}
            />
          </div>
          <span className={styles.dropDown.btn.txt}>Account</span>
        </button>

        {/* Subscription */}
        <button
          className={styles.dropDown.btn.container}
          onClick={() => navigateTo(appRoutes.BILLING)}
        >
          <div className={styles.dropDown.btn.icon.container}>
            <FontAwesomeIcon
              icon={faCrown}
              className={styles.dropDown.btn.icon.gold}
            />
          </div>
          <span className={styles.dropDown.btn.txt}>Subscription</span>
        </button>

        {/* Separator */}
        <div className={styles.dropDown.separator} />

        {/* Logout */}
        <button
          className={styles.dropDown.btn.container}
          onClick={() => requestToLogout()}
        >
          <div className={styles.dropDown.btn.icon.container}>
            <FontAwesomeIcon
              icon={faRightFromBracket}
              className={styles.dropDown.btn.icon.gray}
            />
          </div>
          <span className={styles.dropDown.btn.txt}>Sign out</span>
        </button>
      </div>
    )
  );
};

interface Props {
  user: User;
  isPro?: boolean;
  direction?: Direction;
}

const UserButton: FC<Props> = ({ user, isPro, direction = 'down' }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
      <div className={styles.container}>
        <button
          className={styles.btn.container}
          onClick={() => setIsOpen(!isOpen)}
        >
          <UserInfo user={user} isPro={isPro} />

          <div className={styles.toggle.container(direction, isOpen)}>
            <FontAwesomeIcon
              icon={faAngleUp}
              className={styles.dropDown.btn.icon.gray}
            />
          </div>
        </button>

        <Dropdown
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          direction={direction}
          user={user}
          isPro={isPro}
        />
      </div>
    </ClickAwayListener>
  );
};

export default UserButton;

const styles = {
  container: 'relative flex',
  btn: {
    container:
      'relative flex-1 flex flex-row items-center space-x-3 select-none max-w-72 overflow-hidden',
  },
  left: 'flex-none',
  middle: 'flex flex-col truncate',
  toggle: {
    container: (direction: Direction, isOpen: boolean) =>
      classNames('flex-none transition-transform duration-100 ease-in-out', {
        '-rotate-180':
          (direction === 'up' && isOpen) || (direction === 'down' && !isOpen),
        'rotate-0':
          (direction === 'up' && !isOpen) || (direction === 'down' && isOpen),
      }),
  },

  // UserInfo
  userInfo: {
    container: (className: string) =>
      classNames(
        'flex-1 flex flex-row items-center overflow-hidden space-x-2',
        className
      ),
    left: 'flex-none m-2',
    right: {
      container: 'flex flex-col text-left overflow-hidden',
      name: 'font-bold text-slate-800 text-base truncate',
      username: 'text-gray-500 font-normal text-sm truncate',
    },
  },

  // DropDown
  dropDown: {
    container: (direction: 'up' | 'down') =>
      classNames(
        'absolute',
        direction === 'down'
          ? 'top-full mt-2 right-0'
          : 'bottom-full ml-3 mb-5 inset-x-0',
        'flex flex-col w-60',
        'shadow-lg py-1 bg-white ring-1 ring-black/5 focus:outline-none z-20 rounded-lg'
      ),
    userInfo: 'pl-3 pr-4',
    separator: 'w-full border-t border-gray-30',
    btn: {
      container: classNames(
        'flex-1 flex flex-row items-center text-left my-1 mx-2 px-3 py-2 text-sm text-gray-700',
        'hover:bg-zinc-100 active:bg-neutral-200 rounded-md'
      ),
      icon: {
        container: 'bg-zinc-100 p-2 rounded-md mr-2',
        gold: 'text-[#ffa42e] fa-fw',
        gray: 'text-[#6C727E] fa-fw',
      },
      txt: 'text-slate-800 text-sm font-medium',
    },
  },
};
