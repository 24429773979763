import React, { FC } from 'react';
import classNames from 'classnames';

export enum TextPosition {
  Left,
  Center,
  Right,
}

interface Props {
  text: string;
  textPosition?: TextPosition;
  textStyle?: string;
  leftSeperatorStyle?: string;
  leftSeperatorIsVisible?: boolean;
  rightSeperatorStyle?: string;
  rightSeperatorIsVisible?: boolean;
  style?: string;
}

const TextSeparator: FC<Props> = ({
  text,
  textPosition = TextPosition.Center,
  textStyle,
  leftSeperatorStyle,
  leftSeperatorIsVisible = true,
  rightSeperatorStyle,
  rightSeperatorIsVisible = true,
  style,
}): JSX.Element => {
  const isTextAlignedLeft = textPosition === TextPosition.Left;
  const isTextAlignedRight = textPosition === TextPosition.Right;

  return (
    <div className={classNames('flex items-center', style)}>
      {leftSeperatorIsVisible && (
        <div
          className={classNames(
            'h-0.5 bg bg-slate-800',
            isTextAlignedLeft ? 'w-6' : 'flex-grow',
            leftSeperatorStyle
          )}
        />
      )}

      <div
        className={classNames(
          'flex-grow-0 mx-5 text text-slate-800',
          textStyle
        )}
      >
        {text}
      </div>

      {rightSeperatorIsVisible && (
        <div
          className={classNames(
            'h-0.5 bg bg-white',
            isTextAlignedRight ? 'w-6' : 'flex-grow',
            rightSeperatorStyle
          )}
        />
      )}
    </div>
  );
};

export default TextSeparator;
