import axios, { AxiosInstance } from 'axios';
import { HTTPContentType, HTTPMethod } from 'utils/HTTPUtils';
import { revenueCat as config, RevenueCatConfig } from 'config/global';
import applyCaseMiddleware from 'axios-case-converter';

// Types

export interface CustomerInfo {
  requestDate: string;
  requestDateMs: number;
  subscriber: Subscriber;
}

export interface Subscriber {
  entitlements: Record<string, Entitlement>;
  firstSeen: string;
  lastSeen: string;
  managementUrl: string | null;
  nonSubscriptions: Record<string, NonSubscriptionTransaction[]>;
  originalAppUserId: string;
  originalApplicationVersion: string | null;
  originalPurchaseDate: string | null;
  otherPurchases: Record<string, OtherPurchase>;
  subscriptions: Record<string, Subscription>;
}

export interface Entitlement {
  expiresDate: string | null;
  gracePeriodExpiresDate: string | null;
  productIdentifier: string;
  purchaseDate: string;
  periodType: 'normal' | 'trial' | 'intro';
  billingIssuesDetectedAt: string | null;
  unsubscribeDetectedAt: string | null;
  store: StoreType;
}

export interface Subscription {
  billingIssuesDetectedAt: string | null;
  expiresDate: string | null;
  isSandbox: boolean;
  originalPurchaseDate: string;
  ownershipType: 'PURCHASED' | 'FAMILY_SHARED' | null;
  periodType: 'normal' | 'trial' | 'intro';
  purchaseDate: string;
  store: StoreType;
  unsubscribeDetectedAt: string | null;
  gracePeriodExpiresDate: string | null;
}

export interface NonSubscriptionTransaction {
  id: string;
  isSandbox: boolean;
  originalPurchaseDate: string;
  purchaseDate: string;
  store: StoreType;
  quantity: number;
  type: 'NON_RENEWING_SUBSCRIPTION' | 'CONSUMABLE' | 'NON_CONSUMABLE';
}

export interface OtherPurchase {
  originalPurchaseDate: string;
  purchaseDate: string;
  store: StoreType;
  isSandbox: boolean;
  // Add additional properties if available.
}

export type StoreType =
  | 'APP_STORE'
  | 'MAC_APP_STORE'
  | 'PLAY_STORE'
  | 'STRIPE'
  | 'PROMOTIONAL'
  | 'AMAZON'
  | 'APPLE_SEARCH_ADS'
  | 'UNKNOWN_STORE';

// Client

class RevenueCatClient {
  public config: RevenueCatConfig;
  private axios: AxiosInstance;

  constructor(config: RevenueCatConfig) {
    this.config = config;

    this.axios = applyCaseMiddleware(
      axios.create({
        baseURL: this.config.baseUri,
        headers: {
          'Content-Type': HTTPContentType.JSON,
          Authorization: `Bearer ${this.config.apiKey}`,
        },
      })
    );
  }

  async getCustomerInfo(userId: string): Promise<CustomerInfo> {
    const uri = `/subscribers/${userId}`;

    try {
      const response = await this.axios.get<CustomerInfo>(uri, {
        method: HTTPMethod.GET,
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(
          `Unable to request the customer information: ${error.response?.status} ${error.message}`
        );
      } else {
        throw error;
      }
    }
  }
}

let revenueCatClient: RevenueCatClient;

function getRevenueCatClient(): RevenueCatClient {
  if (!revenueCatClient) {
    revenueCatClient = new RevenueCatClient(config);
  }

  return revenueCatClient;
}

export { getRevenueCatClient };
