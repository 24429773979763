import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openBoxSlideOver } from 'services/SlideOverService';
import {
  faLockKeyhole,
  faPen,
  faTrash,
} from '@fortawesome/pro-solid-svg-icons';
import { Box, getAccessLevel } from 'models/Box';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import useDeleteBox from 'networking/mindfeed/hooks/useDeleteBox';
import { openActionableModal } from 'services/ModalService';
import { ActionableModalPayload } from 'machines/ModalMachine';
import { appRoutes } from 'config/appRoutes';
import Router from 'next/router';
import classNames from 'classnames';

interface Props {
  box: Box;
  isSelected: boolean;
  onSelect?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
}

const MemoryBox: React.FC<Props> = ({
  box,
  isSelected,
  onSelect,
  onEdit,
  onDelete,
}) => {
  const deleteBoxMutation = useDeleteBox({
    removeOnSuccess: false,
  });

  // Do not offer deletion of the default memory box
  const canDelete = box.isDefault !== true;

  const link = `${appRoutes.MEMORY_BOX}/${box.id}`;

  const openDeleteModal = () => {
    const bodyElement = (
      <div className="my-5">
        <span className="block my-2">
          Are you sure you would like to delete this box?
        </span>
        <span className="text-red-500 my-2">
          Please note that all memos created within it will be lost.
        </span>
      </div>
    );
    const payload: ActionableModalPayload = {
      title: `Delete Box "${box.name}"`,
      bodyElement,
      onAccept: () => deleteBoxMutation.mutate(box),
      acceptLabel: 'Delete',
    };
    openActionableModal(payload);
  };

  const onContainerClick = () => {
    Router.push(link);
    if (onSelect) {
      onSelect();
    }
  };

  const onEditClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    openBoxSlideOver(box);
    if (onEdit) {
      onEdit();
    }
  };

  const onDeleteClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    openDeleteModal();

    if (onDelete) {
      onDelete();
    }
  };

  return (
    // We use OnClick instead of a <Link /> attribute to prevent clicks on
    // MenuButton from triggering the parent link
    <div onClick={onContainerClick}>
      <div className={styles.container(isSelected)}>
        <div className={styles.left.container(isSelected)}>
          <FontAwesomeIcon
            icon={faLockKeyhole}
            className={styles.left.icon(isSelected)}
            size="lg"
          />
        </div>

        <div className={styles.middle.container}>
          <p className={styles.middle.title}>{box.name || 'My Memory Box'}</p>
          <p className={styles.middle.subtitle}>
            {box.isDefault ? 'Default' : getAccessLevel(box)}
          </p>
        </div>

        <div className={styles.right.container}>
          <Menu>
            <MenuButton
              className={styles.right.btn.container(isSelected)}
              onClick={(event) => event.stopPropagation()}
            >
              <FontAwesomeIcon
                icon={faEllipsis}
                className={styles.right.btn.icon}
              />
            </MenuButton>
            <MenuItems
              transition
              anchor="bottom end"
              className={styles.right.items.container}
            >
              <MenuItem>
                <button className={styles.right.item.btn} onClick={onEditClick}>
                  <FontAwesomeIcon
                    icon={faPen}
                    className={styles.right.item.icon}
                  />
                  Edit
                </button>
              </MenuItem>
              {canDelete && (
                <MenuItem>
                  <button
                    className={styles.right.item.btn}
                    onClick={onDeleteClick}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className={styles.right.item.icon}
                    />
                    Delete
                  </button>
                </MenuItem>
              )}
            </MenuItems>
          </Menu>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: (isSelected: boolean) =>
    `group flex items-center cursor-pointer select-none pl-2 rounded-[10px] hover:bg-gray-100 ${
      isSelected && 'bg-blue-100 hover:bg-blue-100'
    }`,
  left: {
    container: (isSelected: boolean) =>
      `flex-none rounded-[10px] flex items-center justify-center w-11 h-11 ${
        isSelected ? 'bg-mf-blue' : 'bg-gray-100'
      }`,
    icon: (isSelected: boolean) =>
      `fa-fw text-gray-500 ${isSelected && 'text-white'}`,
  },
  middle: {
    container: 'flex-1 px-3 py-2 overflow-hidden',
    title: 'text-slate-800 font-medium truncate',
    subtitle: 'text-xs font-normal text-gray-500',
  },
  right: {
    container: 'relative mr-2',
    btn: {
      container: (isSelected: boolean) =>
        classNames(
          'inline-flex items-center rounded-md hover:bg-gray-200',
          'py-1.5 px-1 font-semibold focus:outline-none data-[hover]:bg-gray-200 data-[open]:bg-gray-200',
          isSelected && 'visible lg:invisible lg:group-hover:visible',
          !isSelected && 'invisible lg:group-hover:visible'
        ),
      icon: 'fa-fw',
    },
    items: {
      container: classNames(
        'w-40 mt-1.5 origin-top-right rounded-xl bg-white shadow shadow-md p-1 text-sm/6 text-black z-30',
        'transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none',
        'data-[closed]:scale-95 data-[closed]:opacity-0'
      ),
    },
    item: {
      btn: 'group flex w-full items-center gap-2 rounded-lg py-2 px-3 data-[focus]:bg-gray-100',
      icon: 'fa-fw size-4 text-gray-500',
    },
  },
};

export default MemoryBox;
