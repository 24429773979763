import { AccessLevel } from './AccessLevel';

export interface Box {
  id: string;
  accessLevel: AccessLevel;
  isDefault: boolean;
  name: string;
  createdAt: string;
}

export const getAccessLevel = (box: Box): string => {
  switch (box.accessLevel) {
    case AccessLevel.Private:
      return 'Private';
    case AccessLevel.Shared:
      return 'Shared';
    case AccessLevel.Public:
      return 'Public';
    default:
      return box.accessLevel;
  }
};
