export const truncate = (str: string): string => {
  return str.length > 10 ? str.substring(0, 7) + '...' : str;
};

export const capitaliseFirstLetter = (str: string): string => {
  return str[0].toUpperCase() + str.slice(1);
};

export const removeLeadingNonAlphanumeric = (str: string) => {
  return str.replace(/^[^\w\d]+/g, '');
};
