import { ModalService } from 'services/ModalService';
import { StateValue } from 'machines/ModalMachine';
import { useActor } from '@xstate/react';

interface UseModalResult {
  modalState: ReturnType<typeof ModalService.getSnapshot>;
  isClosed: boolean;
  isOpen: boolean;
  view: any;
  payload: any;
}

/**
 * This hook wraps the Modal Service with a few convenience helpers. Destructuring allows to
 * keep the consumer's logic minimal.
 */
export const useModal = (): UseModalResult => {
  const [modalState] = useActor(ModalService);

  // State Helpers
  const isClosed = modalState.matches(StateValue.Closed);
  const isOpen = modalState.matches(StateValue.Open);

  // Context Helpers
  const view = modalState.context.view;
  const payload = modalState.context.payload;

  return {
    modalState,
    isClosed,
    isOpen,
    view,
    payload,
  };
};
