import { Fragment, useState, useEffect } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';
import classNames from 'classnames';
import { Collection } from 'models/Collection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

interface Props {
  collections: Collection[];
  isLoading: boolean;
  defaultCollection?: Collection;
  didSelectCollection?: (collection: Collection | null) => void;
}

export const CollectionSelector: React.FC<Props> = ({
  collections,
  isLoading,
  defaultCollection = null,
  didSelectCollection,
}) => {
  const [selectedCollection, setSelectedCollection] = useState(
    defaultCollection || null
  );

  const handleChange = (id) => {
    const selected = id ? collections.find((col) => col.id === id) : null;
    setSelectedCollection(selected);
    if (didSelectCollection) {
      didSelectCollection(selected);
    }
  };

  // set the initial form values
  useEffect(() => {
    setSelectedCollection(defaultCollection);
  }, [defaultCollection]);

  // set the initial form values
  useEffect(() => {
    if (!selectedCollection) {
      return;
    }
    // If there is a change in the `collections` and the `selectedCollection` has been removed from it,
    // automatically assign the `selectedCollection` to `null`
    const selectedValueStillExists = collections.find(
      (col) => col.id === selectedCollection.id
    );
    if (!selectedValueStillExists) {
      setSelectedCollection(null);
    }
  }, [collections, defaultCollection]);

  return isLoading ? (
    <Fragment />
  ) : (
    <Listbox
      value={selectedCollection ? selectedCollection.id : null}
      onChange={handleChange}
    >
      <ListboxButton
        className={classNames(
          styles.listbox.btn.container.default,
          styles.listbox.btn.container.active
        )}
      >
        <span className={styles.listbox.btn.emoji}>
          {selectedCollection?.emoji ?? '#'}
        </span>
        <span className={styles.listbox.btn.txt}>
          {selectedCollection?.name ?? 'All'}
        </span>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={styles.listbox.btn.chevron}
        />
      </ListboxButton>

      <ListboxOptions
        anchor={{ to: 'bottom start', gap: '4px' }}
        transition
        className={styles.listbox.options.container}
      >
        <ListboxOption
          key="All"
          value={null}
          className={({ selected }) =>
            classNames(
              styles.listbox.options.element.container.default,
              selected
                ? styles.listbox.options.element.container.active
                : styles.listbox.options.element.container.inactive
            )
          }
        >
          <div className={styles.listbox.options.element.left.container}>
            <span className={styles.listbox.options.element.left.emoji}>#</span>
          </div>
          <div className={styles.listbox.options.element.txt}>All</div>
        </ListboxOption>
        {collections &&
          collections.map((collection) => (
            <ListboxOption
              key={collection.id}
              value={collection.id}
              className={({ selected }) =>
                classNames(
                  styles.listbox.options.element.container.default,
                  selected
                    ? styles.listbox.options.element.container.active
                    : styles.listbox.options.element.container.inactive
                )
              }
            >
              <div className={styles.listbox.options.element.left.container}>
                <span className={styles.listbox.options.element.left.emoji}>
                  {collection.emoji ? collection.emoji : '#'}
                </span>
              </div>
              <div className={styles.listbox.options.element.txt}>
                {collection.name}
              </div>
            </ListboxOption>
          ))}
      </ListboxOptions>
    </Listbox>
  );
};

const styles = {
  listbox: {
    btn: {
      container: {
        default: classNames(
          'flex flex-row items-center shrink-0 rounded-full border py-1 px-3 text-left text-sm/6 max-w-40',
          'data-selected:bg-mf-blue active:bg-mf-blue',
          'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
        ),
        active: 'bg-mf-blue text-white border-transparent',
        inactive: 'bg-white text-black border-gray-300 ',
      },
      emoji: 'pr-1.5',
      txt: 'flex-1 pr-1.5 truncate',
      chevron: 'group pointer-events-none top-2.5 right-2.5 size-4',
    },
    options: {
      container: classNames(
        'w-[var(--button-width)] rounded-xl shadow bg-white p-1 [--anchor-gap:var(--spacing-1)] [--anchor-max-height:14rem] focus:outline-none ring-1 ring-black/5',
        'transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0 min-w-52'
      ),
      element: {
        container: {
          default:
            'group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-2 select-none data-[focus]:bg-gray-100 data-[selected]:bg-blue-100 cursor-pointer',
          active: 'bg-mf-blue',
          inactive: '',
        },
        left: {
          container:
            'rounded-lg bg-gray-200 w-8 h-8 flex shrink-0 items-center justify-center group-data-[selected]:bg-mf-blue',
          emoji: 'group-data-[selected]:text-white',
        },
        icon: 'invisible size-4 fill-white group-data-[selected]:visible',
        txt: 'text-sm/6 text-black truncate',
      },
    },
  },
};
