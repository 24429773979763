import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

interface ButtonProps {
  icon: IconProp;
  onClick?: () => void;
  className?: string;
}

const IconButton: React.FC<ButtonProps> = ({ icon, onClick, className = '' }) => {
  return (
    <button
      className={classNames(styles.container, className)}
      onClick={onClick}>
      <FontAwesomeIcon icon={icon} className={styles.icon} />
    </button>
  );
};

export default IconButton;

const styles = {
  container: classNames(
    'w-6 h-6 flex items-center justifiy-center text-center justify-center bg-gray-100 text-gray-900 rounded-full',
    'cursor-pointer hover:bg-blue-700 hover:text-white active:bg-blue-900 active:text-white transition ease-in-out',
    'duration-150'
  ),
  icon: 'fa-fw text-sm'
}