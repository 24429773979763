import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import Link from 'next/link';
import { appRoutes } from 'config/appRoutes';

interface Props {
  className?: string;
}

const ProTrialCard: FC<Props> = ({ className }) => {
  return (
    <div className={className}>
      <div className={styles.container}>
        <div className={styles.header.container}>
          <FontAwesomeIcon icon={faCrown} className={styles.header.icon} />
          <h1 className={styles.header.title}>Mindfeed Pro</h1>
        </div>
        <div>
          <p className={styles.body}>
            Try our most-loved features, free for 7 days.
          </p>
        </div>
        <div className={styles.footer.container}>
          <div>
            <Link href={appRoutes.BILLING} className={styles.footer.link}>
              Try Pro for 7 days
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: classNames(
    'max-w-[262px] p-4 bg-white flex flex-col items-left justify-between shadow-[0px_0px_18px_rgba(0,0,0,0.07)]',
    'rounded-2xl border border-[#f4f4f4]'
  ),
  header: {
    container: 'flex items-center',
    icon: 'text-[#ffa42e] fa-fw pb-0.5',
    title: 'font-inter text-lg font-semibold text-black-500 ml-2',
  },
  body: 'mt-4 text-sm text-gray-500 font-normal text-left',
  footer: {
    container: 'flex items-center justify-center',
    link: classNames(
      'flex items-center justify-center mt-4 px-6 py-2 w-full text-sm text-black-500',
      ' hover:bg-gray-50 border border-gray-300 rounded-full'
    ),
  },
};
export default ProTrialCard;
