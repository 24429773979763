import { useMutation, useQueryClient } from 'react-query';
import type { Collection } from 'models/Collection';
import { QueryKey } from 'services/queryClient';
import { deleteCollection } from 'services/collection.service';
import { isContext } from 'utils/ContextUtils';
import { useXBox } from 'xstate/hooks/useXBox';

type Context = { previousCollections: Collection[] };

const useDeleteCollection = ({
  onSuccess,
  removeOnSuccess = false,
}: {
  onSuccess?: () => void;
  removeOnSuccess?: boolean;
}): ReturnType<typeof useMutation> => {
  const queryClient = useQueryClient();
  const selectedBox = useXBox().getSelectedBox();

  const deleteCollectionMutation = useMutation(deleteCollection, {
    onMutate: async (id) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([QueryKey.COLLECTIONS, selectedBox?.id]);

      // Snapshot the previous value
      const previousCollections = queryClient.getQueryData<Collection[]>([
        QueryKey.COLLECTIONS,
        selectedBox?.id,
      ]);

      // Optimistically update to the new value
      if (previousCollections) {
        queryClient.setQueryData<Collection[]>(
          [QueryKey.COLLECTIONS, selectedBox?.id],
          previousCollections.filter((collection) => {
            return collection.id !== id;
          })
        );
      }

      return { previousCollections };
    },
    onSuccess: async () => {
      if (removeOnSuccess) {
        await queryClient.removeQueries([
          QueryKey.COLLECTIONS,
          selectedBox?.id,
        ]);
      }
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: (_err, _variables, context) => {
      // If the mutation fails, use the context returned from onMutate to roll back
      if (isContext<Context>(context, 'previousCollections')) {
        queryClient.setQueryData<Collection[]>(
          [QueryKey.COLLECTIONS, selectedBox?.id],
          context.previousCollections
        );
      }
    },
  });

  return deleteCollectionMutation;
};

export default useDeleteCollection;
