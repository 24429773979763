import React, { FC } from 'react';
import Image from 'next/image';
import classNames from 'classnames';
import { User } from 'models/User';

interface Props {
  user: User | null;
  withGoldRing?: boolean;
}

const Avatar: FC<Props> = ({ user, withGoldRing = false }): JSX.Element => {
  const imgSrc = user?.getHighResolutionAvatarURL() ?? '/icons/icon-user.svg';

  return (
    <div className={styles.container(withGoldRing)}>
      <Image
        className="h-10 w-10 rounded-full"
        src={imgSrc}
        alt="Avatar"
        fill
        sizes="100%"
      />
    </div>
  );
};

export default Avatar;

const styles = {
  container: (withGoldRing: boolean) =>
    classNames(
      'h-10 w-10 relative max-w-xs flex items-center text-sm rounded-full',
      'ring-2 ring-offset-2 transition-[--tw-ring-color] duration-1000',
      withGoldRing ? 'ring-amber-200' : 'ring-blue-600'
    ),
};
