import { Sidebar, SidebarBody, SidebarHeader, SidebarSection } from './Sidebar';
import { useAuthentication } from 'hooks/useAuthentication';
import SettingsMenu from 'templates/Settings/Nav/SettingsMenu';
import Avatar from 'elements/Avatar';
import Link from 'next/link';
import { appRoutes } from 'config/appRoutes';

const SettingsSidebar = () => {
  const { authenticatedUser } = useAuthentication();

  return (
    <Sidebar>
      <SidebarHeader className="border-none pb-0">
        <Link className={styles.header.container} href={appRoutes.MEMORY_BOX}>
          <div className={styles.header.left}>
            <Avatar user={authenticatedUser} />
          </div>

          <div className={styles.header.right.container}>
            <span className={styles.header.right.top}>
              {authenticatedUser?.getDisplayName() ?? 'Display Name'}
            </span>
            <span className={styles.header.right.bottom}>
              {authenticatedUser?.getUsername() ?? '@username'}
            </span>
          </div>
        </Link>
      </SidebarHeader>

      <SidebarBody className="pt-0">
        <SidebarSection>
          <SettingsMenu />
        </SidebarSection>
      </SidebarBody>
    </Sidebar>
  );
};

export default SettingsSidebar;

const styles = {
  header: {
    container: 'flex flex-row border-none pb-4',
    left: 'pl-2 pr-4 py-2',
    right: {
      container: 'flex-1',
      top: 'block font-semibold',
      bottom: 'text-gray-500 font-normal',
    },
  },
};
