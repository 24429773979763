import classNames from 'classnames';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ValidationMessageProps {
  className?: string;
  showIcon?: boolean;
  children: React.ReactNode;
}

const ValidationMessage: React.FC<ValidationMessageProps> = ({
  className,
  showIcon = false,
  children,
}) => {
  return (
    <div role="alert" className={styles.container(className)}>
      {showIcon && (
        <FontAwesomeIcon icon={faCircleInfo} className={styles.icon} />
      )}
      <span>{children}</span>
    </div>
  );
};

export default ValidationMessage;

const styles = {
  container: (className: string) =>
    classNames('text-rose-600 text-sm my-1', className),
  icon: 'bg-text-rose-600',
};
