import { UseMutationResult, useMutation, useQueryClient } from 'react-query';
import type { Box } from 'models/Box';
import { QueryKey } from 'services/queryClient';
import { saveBox } from '../services/box.service';
import { notify, NotificationType } from 'services/NotificationService';
import { isContext } from 'utils/ContextUtils';

type Context = { previousBoxes: Box[] };

export const useSaveBox = ({
  onSuccess,
}: {
  onSuccess?: (data: Box) => void;
} = {}): UseMutationResult<Box, Error, Box, Context> => {
  const queryClient = useQueryClient();

  const onMutateCallback = async (box: Box) => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    await queryClient.cancelQueries(QueryKey.BOXES);

    // Snapshot the previous value
    const previousBoxes = queryClient.getQueryData<Box[]>(QueryKey.BOXES);

    // Whether the box already exists in the stack
    const itExists =
      previousBoxes instanceof Array &&
      previousBoxes.some((b) => b.id === box.id);

    // Optimistically update to the new value
    if (itExists) {
      queryClient.setQueryData<Box[]>(
        QueryKey.BOXES,
        previousBoxes.map((b) => (b.id !== box.id ? b : box))
      );
    } else {
      // We don't do anything because we don't have an ID to use as key
    }

    return { previousBoxes };
  };

  const onSuccessCallback = async (box: Box, _variables, context) => {
    // Whether the box already exists in the stack
    const itExists =
      isContext<Context>(context, 'previousBoxes') &&
      context.previousBoxes instanceof Array &&
      context.previousBoxes.some((b) => b.id === box.id);

    if (itExists) {
      // Update the recently updated box
      queryClient.setQueryData<Box[]>(
        QueryKey.BOXES,
        context.previousBoxes.map((b) => (b.id !== box.id ? b : box))
      );

      // Notify User
      notify(NotificationType.BOX_UPDATED);
    } else {
      // Add the newly created box
      queryClient.setQueryData<Box[] | undefined>(
        QueryKey.BOXES,
        (data: Box[]) => {
          return data && [...data, box];
        }
      );

      // Notify User
      notify(NotificationType.BOX_CREATED);
    }

    if (onSuccess) {
      onSuccess(box);
    }
  };

  const onErrorCallback = (_err, _variables, context) => {
    // If the mutation fails, use the context returned from onMutate to roll back
    if (isContext<Context>(context, 'previousBoxes')) {
      queryClient.setQueryData<Box[]>([QueryKey.BOXES], context.previousBoxes);
    }
  };

  const saveBoxMutation = useMutation(saveBox, {
    onMutate: onMutateCallback,
    onSuccess: onSuccessCallback,
    onError: onErrorCallback,
  });

  return saveBoxMutation;
};
