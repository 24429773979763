import { HTTPMethod } from 'utils/HTTPUtils';
import type { Box } from 'models/Box';
import { authenticationHeaders } from 'services/firebase';
import { mindfeed as config } from 'config/global';

export const BOXES_URI = `${config.baseUri}/api/boxes`;

export const getBoxes = async (): Promise<Box[]> => {
  const response = await fetch(BOXES_URI, {
    method: HTTPMethod.GET,
    headers: await authenticationHeaders(),
  });

  if (!response.ok) {
    throw new Error(`Unable to fetch boxes: error ${response.status}`);
  }

  return response.json() as Promise<Box[]>;
};

export const saveBox = async ({
  id,
  ...restBoxProperties
}: Box): Promise<Box> => {
  const body = JSON.stringify({
    name: restBoxProperties.name,
    ...(id ? {} : { accessLevel: restBoxProperties.accessLevel }),
  });

  const url = id ? `${BOXES_URI}/${id}` : BOXES_URI;
  const method = id ? HTTPMethod.PUT : HTTPMethod.POST;

  const response = await fetch(url, {
    method,
    headers: await authenticationHeaders(),
    body,
  });

  if (!response.ok) {
    throw new Error(`Unable to save box: error ${response.status}`);
  }

  return response.json() as Promise<Box>;
};

export const deleteBox = async ({ id }: Box): Promise<Box> => {
  const response = await fetch(`${BOXES_URI}/${id}`, {
    method: HTTPMethod.DELETE,
    headers: await authenticationHeaders(),
  });

  if (!response.ok) {
    throw new Error(`Unable to delete box: error ${response.status}`);
  }

  return response.json() as Promise<Box>;
};
