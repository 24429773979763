import { SlideOverService } from 'services/SlideOverService';
import { StateValue } from 'machines/SlideOverMachine';
import { useActor } from '@xstate/react';

interface UseSlideOverResult {
  slideOverState: ReturnType<typeof SlideOverService.getSnapshot>;
  isClosed: boolean;
  isOpen: boolean;
  view: any;
  payload: any;
}

/**
 * This hook wraps the SlideOver Service with a few convenience helpers. Destructuring allows to
 * keep the consumer's logic minimal.
 */
export const useSlideOver = (): UseSlideOverResult => {
  const [slideOverState] = useActor(SlideOverService);

  // State Helpers
  const isClosed = slideOverState.matches(StateValue.Closed);
  const isOpen = slideOverState.matches(StateValue.Open);

  // Context Helpers
  const view = slideOverState.context.view;
  const payload = slideOverState.context.payload;

  return {
    slideOverState,
    isClosed,
    isOpen,
    view,
    payload,
  };
};
