import { useSelector } from '@xstate/react';
import { Selectors, getBoxService } from '../services/BoxService';

/**
 * This hook wraps the XState Box Service with a few convenience helpers.
 * Destructuring allows to keep the consumer's logic minimal.
 */
export const useXBox = () => {
  // State Helpers
  const isCheckingSelection = useSelector(
    getBoxService(),
    Selectors.isCheckingSelection
  );
  const isSelected = useSelector(getBoxService(), Selectors.isSelected);
  const isUnselected = useSelector(getBoxService(), Selectors.isUnselected);

  // Context Helpers

  // Deferred State Access to Prevent Unnecessary Re-renders:
  // i.e we use functions to access specific parts of the state on demand. This approach ensures that useXBox
  // does not trigger re-renders based on unused state changes.

  const getSelectedBox = () =>
    useSelector(getBoxService(), Selectors.selectedBox);
  const getSelectedCollection = () =>
    useSelector(getBoxService(), Selectors.selectedCollection);

  return {
    isCheckingSelection,
    isSelected,
    isUnselected,
    getSelectedBox,
    getSelectedCollection,
  };
};
