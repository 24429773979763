import { createMachine, assign } from 'xstate';

export enum SidebarView {
  Box, // refers to component Sidebar/BoxSidebar
  Collection, // refers to component Sidebar/CollectionSidebar
  Settings, // refers to component Sidebar/SettingsSidebar
}

export enum SidebarPosition {
  Left, // appears from the left of the screen
  Right, // appears from the right of the screen
}

// State

export enum StateValue {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

const CLOSED = StateValue.Closed;
const OPEN = StateValue.Open;

type SidebarMachineState =
  | {
      value: StateValue.Open;
      context: SidebarMachineContext;
    }
  | {
      value: StateValue.Closed;
      context: SidebarMachineContext;
    };

// Events

export enum EventType {
  RequestOpen = 'REQUEST_OPEN',
  RequestClose = 'REQUEST_CLOSE',
}

type RequestOpenSidebarEvent = {
  type: EventType.RequestOpen;
  view?: SidebarView;
  position?: SidebarPosition;
};

type RequestCloseSidebarEvent = {
  type: EventType.RequestClose;
  view?: SidebarView;
};

export type SidebarMachineEvent =
  | RequestOpenSidebarEvent
  | RequestCloseSidebarEvent;

export interface SidebarMachineContext {
  view?: SidebarView;
  position?: SidebarPosition;
}

/**
 * Check if an event is of type T - this is basically saying:
 *
 * ```
 * const isRequestCloseMachineEvent = (event : SidebarMachineEvent): event is RequestCloseMachineEvent => {
 *   return (event as RequestCloseMachineEvent) !== undefined;
 * }
 * ```
 */
const isEvent = <T extends SidebarMachineEvent>(
  event: SidebarMachineEvent
): event is T => {
  return (event as T) !== undefined;
};

const shouldCloseSidebar = (
  context: SidebarMachineContext,
  event: SidebarMachineEvent
) => {
  // Do not close the sidebar if the wrong event has been fired
  if (!isEvent<RequestCloseSidebarEvent>(event)) {
    return false;
  }

  // Close the sidebar if no views are provided
  if (context.view == null || event.view == null) {
    return true;
  }

  // Close the sidebar if the views do match
  if (context.view === event.view) {
    return true;
  }

  // Do not close the modal otherwise
  return false;
};

export const sidebarMachine = createMachine<
  SidebarMachineContext,
  SidebarMachineEvent,
  SidebarMachineState
>(
  {
    id: 'sidebar',
    predictableActionArguments: true,
    context: {
      view: null,
      position: null,
    },
    initial: CLOSED,
    states: {
      [CLOSED]: {
        exit: 'reset',
        on: {
          [EventType.RequestOpen]: {
            target: OPEN,
            actions: ['setView', 'setPosition'],
          },
        },
      },
      [OPEN]: {
        exit: 'reset',
        on: {
          [EventType.RequestClose]: {
            target: CLOSED,
            cond: shouldCloseSidebar,
          },
          [EventType.RequestOpen]: {
            target: OPEN,
            actions: ['setView'],
          },
        },
      },
    },
  },
  {
    services: {
      // Services
    },
    actions: {
      reset: assign((_ctx: any, _event: any) => ({
        view: null,
      })),
      setView: assign((_ctx: any, event: any) => ({
        view: event.view,
      })),
      setPosition: assign((_ctx: any, event: any) => ({
        position: event.position,
      })),
    },
  }
);
