import { SidebarService } from 'xstate/services/SidebarService';
import { SidebarPosition, StateValue } from 'xstate/machines/SidebarMachine';
import { useActor } from '@xstate/react';

interface UseSidebarResult {
  sidebarState: ReturnType<typeof SidebarService.getSnapshot>;
  isClosed: boolean;
  isOpen: boolean;
  view: any;
  position: SidebarPosition;
}

export const useSidebar = (): UseSidebarResult => {
  const [sidebarState] = useActor(SidebarService);

  // State Helpers
  const isClosed = sidebarState.matches(StateValue.Closed);
  const isOpen = sidebarState.matches(StateValue.Open);

  // Context Helpers
  const view = sidebarState.context.view;
  const position = sidebarState.context.position;

  return {
    sidebarState,
    isClosed,
    isOpen,
    view,
    position,
  };
};
