import { createMachine, assign } from 'xstate';
import type { Collection } from 'models/Collection';
import type { Box } from 'models/Box';

export enum SlideOverView {
  Box, // refers to component SlideOver/BoxSlideOver
  Collection, // refers to component SlideOver/CollectionSlideOver
}

export type BoxSlideOverPayload = {
  box?: Box;
};

export type CollectionSlideOverPayload = {
  collection?: Collection;
};

export type SlideOverPayload = BoxSlideOverPayload | CollectionSlideOverPayload;

// State

export enum StateValue {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

const CLOSED = StateValue.Closed;
const OPEN = StateValue.Open;

type SlideOverMachineState =
  | {
      value: StateValue.Open;
      context: SlideOverMachineContext;
    }
  | {
      value: StateValue.Closed;
      context: SlideOverMachineContext;
    };

// Events

export enum EventType {
  RequestOpen = 'REQUEST_OPEN',
  RequestClose = 'REQUEST_CLOSE',
}

type RequestOpenSlideOverEvent = {
  type: EventType.RequestOpen;
  view?: SlideOverView;
  payload?: SlideOverPayload;
};

type RequestCloseSlideOverEvent = {
  type: EventType.RequestClose;
  view?: SlideOverView;
};

export type SlideOverMachineEvent =
  | RequestOpenSlideOverEvent
  | RequestCloseSlideOverEvent;

export interface SlideOverMachineContext {
  view?: SlideOverView;
  payload?: SlideOverPayload;
}

/**
 * Check if an event is of type T - this is basically saying:
 *
 * ```
 * const isRequestCloseMachineEvent = (event : SlideOverMachineEvent): event is RequestCloseMachineEvent => {
 *   return (event as RequestCloseMachineEvent) !== undefined;
 * }
 * ```
 */
const isEvent = <T extends SlideOverMachineEvent>(
  event: SlideOverMachineEvent
): event is T => {
  return (event as T) !== undefined;
};

const shouldCloseSlideOver = (
  context: SlideOverMachineContext,
  event: SlideOverMachineEvent
) => {
  // Do not close the slide over if the wrong event has been fired
  if (!isEvent<RequestCloseSlideOverEvent>(event)) {
    return false;
  }

  // Close the slide over if no views are provided
  if (context.view == null || event.view == null) {
    return true;
  }

  // Close the slide over if the views do match
  if (context.view === event.view) {
    return true;
  }

  // Do not close the modal otherwise
  return false;
};

export const slideOverMachine = createMachine<
  SlideOverMachineContext,
  SlideOverMachineEvent,
  SlideOverMachineState
>(
  {
    id: 'slideover',
    predictableActionArguments: true,
    context: {
      view: null,
      payload: null,
    },
    initial: CLOSED,
    states: {
      [CLOSED]: {
        exit: 'reset',
        on: {
          [EventType.RequestOpen]: {
            target: OPEN,
            actions: ['setView', 'setPayload'],
          },
        },
      },
      [OPEN]: {
        exit: 'reset',
        on: {
          [EventType.RequestClose]: {
            target: CLOSED,
            cond: shouldCloseSlideOver,
          },
          [EventType.RequestOpen]: {
            target: OPEN,
            actions: ['setView', 'setPayload'],
          },
        },
      },
    },
  },

  {
    services: {
      // Services
    },
    actions: {
      reset: assign((_ctx: any, _event: any) => ({
        view: null,
        payload: null,
      })),
      setPayload: assign((_ctx: any, event: any) => ({
        payload: event.payload,
      })),
      setView: assign((_ctx: any, event: any) => ({
        view: event.view,
      })),
    },
  }
);
