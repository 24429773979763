import React, { FC, ReactElement, useMemo } from 'react';
import useBoxes from 'networking/mindfeed/hooks/useBoxes';
import { Box } from 'models/Box';
import { useXBox } from 'xstate/hooks/useXBox';
import MemoryBox from './MemoryBox';
import { removeLeadingNonAlphanumeric } from 'utils/StringUtils';

interface Props {
  onBoxSelect?: () => void;
  onBoxEdit?: () => void;
  onBoxDelete?: () => void;
}

const MemoryBoxList: FC<Props> = ({
  onBoxSelect,
  onBoxEdit,
  onBoxDelete,
}): ReactElement => {
  const { data: boxes } = useBoxes();
  const { getSelectedBox } = useXBox();
  const selectedBox = getSelectedBox();

  const sortedBoxes = useMemo(() => {
    if (!boxes) return [];

    // Create a collator with numeric sorting enabled. We use Intl.Collator with the numeric
    // option to sort numbers in strings naturally (e.g., 'Box 2' before 'Box 10')
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    });

    const compareNaturally = (a: Box, b: Box) => {
      const nameA = removeLeadingNonAlphanumeric(a.name).trim();
      const nameB = removeLeadingNonAlphanumeric(b.name).trim();
      return collator.compare(nameA, nameB);
    };

    const sortBoxes = (a: Box, b: Box) => {
      // Boxes with `isDefault = true` come first
      if (a.isDefault) return -1;
      if (b.isDefault) return 1;

      return compareNaturally(a, b);
    };

    return [...boxes].sort(sortBoxes);
  }, [boxes]);

  return (
    <div className={styles.container}>
      {sortedBoxes?.map((box: Box) => {
        const isSelected = selectedBox?.id === box.id;

        return (
          <MemoryBox
            key={box.id}
            box={box}
            isSelected={isSelected}
            onSelect={onBoxSelect}
            onEdit={onBoxEdit}
            onDelete={onBoxDelete}
          />
        );
      })}
    </div>
  );
};

const styles = {
  container: 'space-y-2',
};

export default MemoryBoxList;
