import { AuthRegistrationType, AuthMethodType } from 'models/Auth';
import { SubscriptionType } from 'models/Subscription';

export enum AnalyticsEventName {
  USER_REGISTERED = 'User Registered',
  USER_LOGGED_IN = 'User Logged In',
  USER_LOGGED_OUT = 'User Logged Out',
  USER_CLICKED = 'User Clicked',
}

export interface IAnalyticsEvent {
  name: AnalyticsEventName;
  properties: Record<string, any>;
}

export type UserRegisteredProperties = {
  subscription: SubscriptionType;
  typeOfRegistration: AuthRegistrationType;
};

export type ClickedOnProperties = {
  title: string;
  location?: string;
};

export type LoggedInProperties = {
  typeOfLogin: AuthMethodType;
};

export type LoggedOutProperties = object;
// ... TODO

export type UserRegisteredEvent = IAnalyticsEvent & {
  name: AnalyticsEventName.USER_REGISTERED;
  properties: UserRegisteredProperties;
};

export type UserLoggedInEvent = IAnalyticsEvent & {
  name: AnalyticsEventName.USER_LOGGED_IN;
  properties: LoggedInProperties;
};

export type UserLoggedOutEvent = IAnalyticsEvent & {
  name: AnalyticsEventName.USER_LOGGED_OUT;
  properties: LoggedOutProperties;
};

export type ClickedOnEvent = IAnalyticsEvent & {
  name: AnalyticsEventName.USER_CLICKED;
  properties: ClickedOnProperties;
};

export type SegmentEvent =
  | UserRegisteredEvent
  | UserLoggedInEvent
  | UserLoggedOutEvent
  | ClickedOnEvent;
