import { ReactElement } from 'react';
import { CollectionForm } from 'elements/Collection';
import { CollectionSlideOverPayload } from 'machines/SlideOverMachine';
import { closeSlideOver } from 'services/SlideOverService';
import { useSlideOver } from 'hooks/useSlideOver';

const CollectionSlideOver = (): ReactElement => {
  const { payload } = useSlideOver() as { payload: CollectionSlideOverPayload };

  return (
    <CollectionForm
      onSuccess={closeSlideOver}
      collection={payload.collection}
    />
  );
};

export default CollectionSlideOver;
