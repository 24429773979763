import React from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  CloseButton,
} from '@headlessui/react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useSidebar } from 'xstate/hooks/useSidebar';
import { closeSidebar } from 'xstate/services/SidebarService';
import { SidebarPosition, SidebarView } from 'xstate/machines/SidebarMachine';
import BoxSidebar from './BoxSidebar';
import CollectionSidebar from './CollectionSidebar';
import SettingsSidebar from './SettingsSidebar';

function SidebarDialog() {
  const { isOpen, view, position } = useSidebar();

  const renderSidebar = () => {
    switch (view) {
      case SidebarView.Box:
        return <BoxSidebar />;
      case SidebarView.Collection:
        return <CollectionSidebar />;
      case SidebarView.Settings:
        return <SettingsSidebar />;
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeSidebar}
      className={styles.dialog.container}
    >
      <DialogBackdrop transition className={styles.dialog.backdrop} />
      <DialogPanel
        transition
        className={styles.dialog.panel.container(position)}
      >
        <div className={styles.dialog.panel.inner}>
          <div className={styles.dialog.panel.nav}>
            <CloseButton
              as={'button'}
              aria-label="Close navigation"
              className={styles.dialog.panel.btn.container}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className={styles.dialog.panel.btn.icon}
                size="xl"
              />
            </CloseButton>
          </div>

          {renderSidebar()}
        </div>
      </DialogPanel>
    </Dialog>
  );
}

export default SidebarDialog;

const styles = {
  dialog: {
    container: 'lg:hidden',
    backdrop: classNames(
      'fixed inset-0 bg-black/30 transition data-[closed]:opacity-0 data-[enter]:duration-300',
      'data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in z-20'
    ),
    panel: {
      container: (position: SidebarPosition) =>
        classNames(
          'fixed inset-y-0',
          'w-full max-w-80 p-2 transition duration-300 ease-in-out z-20',
          position === SidebarPosition.Left
            ? 'left-0 data-[closed]:-translate-x-full'
            : 'right-0 data-[closed]:translate-x-full'
        ),
      inner: 'flex h-full flex-col rounded-lg bg-white',
      nav: '-mb-3 px-1 pt-3',
      btn: {
        container: 'bg-white hover:bg-white p-2',
        icon: 'fa-fw text-[#6C727E] cursor-pointer',
      },
    },
  },
};
