export enum NotificationKind {
  Error = 'ERROR',
  Info = 'INFO',
  Success = 'SUCCESS',
  Warning = 'WARNING',
}

export interface Notification {
  id: string;
  kind: NotificationKind;
  title: string;
  visible: boolean;
}
