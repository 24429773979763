import { useSubscriptionInfo } from './useSubscriptionInfo';

export const useProStatus = () => {
  const {
    data: customerInfo,
    isLoading,
    isError,
    error,
  } = useSubscriptionInfo();

  const hasProEntitlement = customerInfo?.subscriber.entitlements?.pro;
  const isProActive =
    hasProEntitlement && new Date(hasProEntitlement.expiresDate) > new Date();

  return {
    isPro: !!isProActive, // Return true if the user has a valid "pro" entitlement
    isLoading,
    isError,
    error,
  };
};
