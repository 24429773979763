import React, { FC, Fragment, ReactElement } from 'react';
import { Transition } from '@headlessui/react';
import {
  Notification as NotificationModel,
  NotificationKind,
} from 'models/Notification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';

interface NotificationProps {
  notification: NotificationModel;
}

const Notification: FC<NotificationProps> = ({
  notification,
}): ReactElement => {
  const iconMap = {
    [NotificationKind.Success]: faCircleCheck,
    [NotificationKind.Error]: faCircleXmark,
    [NotificationKind.Info]: faInfoCircle,
    [NotificationKind.Warning]: faExclamationTriangle,
  };
  const icon = iconMap[notification.kind];

  return (
    <Transition
      show={notification.visible}
      as={Fragment}
      enter="ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className={styles.container}>
        <span>
          <FontAwesomeIcon icon={icon} />
        </span>
        <span className={styles.title}>{notification.title}</span>
      </div>
    </Transition>
  );
};

export default Notification;

const styles = {
  container: classNames(
    'fixed bottom-0 left-1/2 transform -translate-x-1/2 mb-4 h-12 w-auto bg-slate-800 text-white text-center',
    'rounded-xl items-center justify-between p-3 shadow-lg pointer-events-auto ring-1 ring-black/5 overflow-hidden'
  ),
  title: 'text-base text-white font-normal ml-3',
};
