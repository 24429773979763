import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { HTTPContentType } from 'utils/HTTPUtils';
import {
  signInWithPopup,
  signOut,
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  UserCredential,
} from 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_GOOGLE_CLOUD_IDENTITY_PLATFORM_API_KEY,
  authDomain:
    process.env.NEXT_PUBLIC_GOOGLE_CLOUD_IDENTITY_PLATFORM_AUTH_DOMAIN,
};

// Firebase instance
let instance: firebase.app.App | null = null;

// Initialize Firebase or get the existing instance
export default function getFirebase(): firebase.app.App | null {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(firebaseConfig);
    return instance;
  }
  return null;
}

/**
 * Firebase Current User
 *
 * We make the decision to manage the `currentUser` globally.
 * Use a Persistent Auth State Listener - we maintain the listener to keep track of the current user.
 */

// Firebase current user
let currentUser: firebase.User | null = null;

function initAuthListener() {
  getFirebase()
    ?.auth()
    .onAuthStateChanged((user) => {
      currentUser = user;
    });
}

// Initialise this listener when the app starts
initAuthListener();

/**
 * Providers
 */

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });

const facebookProvider = new FacebookAuthProvider();
facebookProvider.setCustomParameters({ display: 'popup' });

const appleProvider = new OAuthProvider('apple.com');
appleProvider.setCustomParameters({ display: 'select_account' });
appleProvider.addScope('email');
appleProvider.addScope('name');

/**
 * Authenticate With Google
 *
 * https://firebase.google.com/docs/auth/web/google-signin
 */
export const authenticateWithGoogle = async (): Promise<UserCredential> => {
  const firebaseApp = getFirebase();
  if (!firebaseApp) {
    throw new Error('Firebase is not initialized');
  }
  return signInWithPopup(firebaseApp.auth(), googleProvider);
};

/**
 * Authenticate With Facebook
 *
 * https://firebase.google.com/docs/auth/web/facebook-login
 */
export const authenticateWithFacebook = async (): Promise<UserCredential> => {
  const firebaseApp = getFirebase();
  if (!firebaseApp) {
    throw new Error('Firebase is not initialized');
  }
  return signInWithPopup(firebaseApp.auth(), facebookProvider);
};

/**
 * Authenticate With Apple
 *
 * https://firebase.google.com/docs/auth/web/apple
 */
export const authenticateWithApple = async (): Promise<UserCredential> => {
  const firebaseApp = getFirebase();
  if (!firebaseApp) {
    throw new Error('Firebase is not initialized');
  }
  return signInWithPopup(firebaseApp.auth(), appleProvider);
};

/**
 * Authenticate With Email
 *
 * https://firebase.google.com/docs/auth/web/email-link-auth
 */
export const authenticateWithEmail = async (): Promise<void> => {
  // TODO: Implement Sign In With Email
  throw new Error('Sign In With Email not implemented');
};

/**
 * Sign Out
 *
 * https://firebase.google.com/docs/auth/web/google-signin#next_steps
 * https://firebase.google.com/docs/auth/web/facebook-login#next_steps
 * https://firebase.google.com/docs/reference/unity/class/firebase/auth/firebase-auth#class_firebase_1_1_auth_1_1_firebase_auth_1ab53ba445fd7770ee2e9af595258b0231
 */
export const unauthenticate = async (): Promise<void> => {
  const firebaseApp = getFirebase();
  if (!firebaseApp) {
    throw new Error('Firebase is not initialized');
  }
  return signOut(firebaseApp.auth());
};

/**
 * Authentication Headers
 *
 * https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
 */

export const authenticationHeaders = async (): Promise<HeadersInit> => {
  if (!currentUser) {
    // Optionally, wait for the auth state to initialize
    await new Promise((resolve) => {
      const unsubscribe = getFirebase()
        .auth()
        .onAuthStateChanged((user) => {
          currentUser = user;
          unsubscribe();
          resolve(null);
        });
    });
  }

  if (!currentUser) {
    // Handle unauthenticated state
    throw new Error('User is not authenticated');
  }

  const token = await currentUser.getIdToken(false);
  const headers = new Headers({
    'Content-Type': HTTPContentType.JSON,
    Authorization: `Bearer ${token}`,
  });

  return headers;
};
