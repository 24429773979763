import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSaveDrop, Variables } from 'hooks/useSaveDrop';
import { Drop } from 'models/Drop';
import type { Collection } from 'models/Collection';
import { DropFormSkeleton } from './DropFormSkeleton';
import useCollections from 'hooks/useCollections';
import { CollectionSelector } from './CollectionSelector';
import { Quote } from 'models/openGraph/Quote';

interface Props {
  quote: Quote;
  onSuccess?: (drop: Drop) => void;
  onCancel?: () => void;
}

export const SaveQuoteForm: React.FC<Props> = ({
  quote,
  onSuccess,
  onCancel,
}) => {
  const { data: collections, isLoading: isLoadingCollections } =
    useCollections();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm({ mode: 'onBlur' });

  const [defaultCollection, setDefaultCollection] =
    useState<Collection>(undefined);

  const saveDropMutation = useSaveDrop({
    onSuccess: (data: Drop) => {
      reset();
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });

  const onSubmit = async (data: Drop) => {
    const variables: Variables = {
      drop: data,
    };

    saveDropMutation.mutate(variables);
  };

  useEffect(() => {
    setValue('type', 'graph');
    setValue('item', {
      type: 'quote',
      id: quote.id,
    });
  }, []);

  useEffect(() => {
    if (!collections) return;

    // Finally, if none of the above, don't set any default collection
    setDefaultCollection(undefined);
    setValue('collectionId', null);
  }, [
    collections, // If any of these change, the default collection too
    isSubmitSuccessful, // This allows useEffect to be called after a successful submission to set
    // a default collection to prepare the form for the next submission.
  ]);

  return saveDropMutation.isLoading ? (
    <DropFormSkeleton />
  ) : (
    <form className="relative" onSubmit={handleSubmit(onSubmit)}>
      <div className="bottom-0 inset-x-px">
        {/* CollectionSelector */}
        <div
          className="flex flex-nowrap justify-end py-2 px-2 space-x-2 sm:px-3 justify-center border border-dashed mx-2 rounded-xl"
          {...register('collectionId')}
        >
          {collections && (
            <CollectionSelector
              collections={collections}
              isLoading={isLoadingCollections}
              defaultCollection={defaultCollection}
              didSelectCollection={(col) =>
                setValue('collectionId', col ? col.id : null)
              }
            />
          )}
        </div>

        {/* Submit */}
        <div className="mt-5 sm:mt-8 sm:flex sm:flex-row-reverse px-4 sm:px-6 pt-4 border-t border-gray-200">
          <button
            type="submit"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-mf-dark-blue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          >
            Add to Memory Box
          </button>

          {onCancel && (
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={onCancel}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    </form>
  );
};
