import { useQuery, UseQueryOptions } from 'react-query';
import { getCollections } from 'services/collection.service';
import { QueryKey } from 'services/queryClient';
import type { Collection } from 'models/Collection';
import { useXBox } from 'xstate/hooks/useXBox';

type QueryKeyType = [QueryKey.COLLECTIONS, string?];

type Options = Omit<
  UseQueryOptions<Collection[], unknown, Collection[], QueryKeyType>,
  'queryKey' | 'queryFn'
>;

type Params = {
  options?: Options;
};

export default function useCollections({ options = {} }: Params = {}) {
  const selectedBox = useXBox().getSelectedBox();

  const defaultOptions: Options = {
    // Some Default Options
  };

  return useQuery(
    [QueryKey.COLLECTIONS, selectedBox?.id],
    () => getCollections(selectedBox),
    { ...defaultOptions, ...options }
  );
}
