import React, { FC } from 'react';
import { AuthMethodType } from 'models/Auth';
import AppleLogo from '../../../public/icons/icon-apple.svg';
import FacebookLogo from '../../../public/icons/icon-facebook.svg';
import GoogleLogo from '../../../public/icons/icon-google.svg';
import classNames from 'classnames';

interface Props {
  authMethod?: AuthMethodType;
  onClick: () => void;
}

const SignInBtn: FC<Props> = ({ authMethod, onClick }): JSX.Element => {
  const handleBtnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    onClick();
  };

  const Logo = (): JSX.Element => {
    switch (authMethod) {
      case AuthMethodType.Apple:
        return <AppleLogo width={24} height={24} />;
      case AuthMethodType.Facebook:
        return <FacebookLogo width={24} height={24} />;
      default:
        return <GoogleLogo width={24} height={24} />;
    }
  };

  const Title = ({ className }): JSX.Element => {
    switch (authMethod) {
      case AuthMethodType.Apple:
        return <span className={className}>Sign in with Apple</span>;
      case AuthMethodType.Facebook:
        return <span className={className}>Sign in with Facebook</span>;
      default:
        return <span className={className}>Sign in with Google</span>;
    }
  };

  return (
    <button className={styles.container} onClick={handleBtnClick}>
      <Logo />
      <Title className={styles.title} />
    </button>
  );
};

export default SignInBtn;

const styles = {
  container: classNames(
    'flex flex-row w-72 rounded-full border border-1 border-[#D9E0ED] justify-center',
    'items-center space-x-2 p-2.5 hover:bg-[#F9FAFB]'
  ),
  title: 'text-sm font-semibold',
};
