import React, { FC, ReactElement, useMemo } from 'react';
import { removeLeadingNonAlphanumeric } from 'utils/StringUtils';
import { useXBox } from 'xstate/hooks/useXBox';
import useCollections from 'hooks/useCollections';
import { Collection, FakeCollection } from './Collection';
import { Collection as CollectionModel } from 'models/Collection';

interface Props {
  onCollectionSelect?: () => void;
  onCollectionEdit?: () => void;
  onCollectionDelete?: () => void;
}

const CollectionList: FC<Props> = ({
  onCollectionSelect,
  onCollectionEdit,
  onCollectionDelete,
}): ReactElement => {
  const { data: collections } = useCollections();
  const { getSelectedCollection } = useXBox();
  const selectedCollection = getSelectedCollection();

  const sortedCollections = useMemo(() => {
    if (!collections) return [];

    // Create a collator with numeric sorting enabled. We use Intl.Collator with the numeric
    // option to sort numbers in strings naturally (e.g., 'Collection 2' before 'Collection 10')
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base',
    });

    const compareNaturally = (a: CollectionModel, b: CollectionModel) => {
      const nameA = removeLeadingNonAlphanumeric(a.name).trim();
      const nameB = removeLeadingNonAlphanumeric(b.name).trim();
      return collator.compare(nameA, nameB);
    };

    return [...collections].sort(compareNaturally);
  }, [collections]);

  return (
    <div className="space-y-2">
      <FakeCollection
        isSelected={!selectedCollection}
        onSelect={onCollectionSelect}
      />

      {sortedCollections?.map((collection) => {
        const isSelected = selectedCollection?.id === collection.id;

        return (
          <Collection
            key={collection.id}
            collection={collection}
            isSelected={isSelected}
            onSelect={onCollectionSelect}
            onEdit={onCollectionEdit}
            onDelete={onCollectionDelete}
          />
        );
      })}
    </div>
  );
};

export default CollectionList;
