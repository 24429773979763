import { interpret } from 'xstate';
import {
  EventType,
  LoginModalPayload,
  modalMachine,
  ModalView,
  SaveQuoteModalPayload,
  ActionableModalPayload,
} from 'machines/ModalMachine';
import { Quote } from 'models/openGraph/Quote';

export const ModalService = interpret(modalMachine);
ModalService.start();

export const closeModal = (): void => {
  ModalService.send(EventType.RequestClose);
};

export const closeLoginModal = (): void => {
  ModalService.send(EventType.RequestClose, {
    view: ModalView.Login,
  });
};

export const openLoginModal = (redirectTo?: string): void => {
  const payload: LoginModalPayload = {
    redirectTo,
  };
  ModalService.send(EventType.RequestOpen, {
    view: ModalView.Login,
    payload,
  });
};

export const openSaveQuoteModal = (quote: Quote): void => {
  const payload: SaveQuoteModalPayload = {
    quote,
  };
  ModalService.send(EventType.RequestOpen, {
    view: ModalView.SaveQuote,
    payload,
  });
};

export const openActionableModal = (payload: ActionableModalPayload): void => {
  ModalService.send(EventType.RequestOpen, {
    view: ModalView.Actionable,
    payload,
  });
};
