import { DialogPanel, DialogTitle } from '@headlessui/react';
import React, { ReactElement } from 'react';
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useModal } from 'hooks/useModal';
import { ActionableModalPayload } from 'machines/ModalMachine';
import { closeModal } from 'services/ModalService';

const ActionableModal = (): ReactElement => {
  const { payload } = useModal() as { payload: ActionableModalPayload };
  const { title, bodyElement, onAccept, acceptLabel, rejectLabel } = payload;

  return (
    <div className={styles.outer}>
      <div className={styles.inner}>
        <DialogPanel transition className={styles.panel.container}>
          <div className={styles.panel.header.container}>
            <button
              type="button"
              onClick={closeModal}
              className={styles.panel.header.btn.container}
            >
              <XMarkIcon
                aria-hidden="true"
                className={styles.panel.header.btn.icon}
              />
            </button>
          </div>

          <div className={styles.panel.body.container}>
            <div className={styles.panel.body.left.container}>
              <ExclamationTriangleIcon
                aria-hidden="true"
                className={styles.panel.body.left.icon}
              />
            </div>
            <div className={styles.panel.body.right.container}>
              <DialogTitle as="h3" className={styles.panel.body.right.top}>
                {title}
              </DialogTitle>
              <div className={styles.panel.body.right.bottom}>
                {bodyElement}
              </div>
            </div>
          </div>

          <div className={styles.panel.footer.container}>
            <button
              type="button"
              onClick={() => {
                onAccept();
                closeModal();
              }}
              className={styles.panel.footer.buttons.right}
            >
              {acceptLabel ?? 'Ok'}
            </button>
            <button
              type="button"
              onClick={closeModal}
              className={styles.panel.footer.buttons.left}
            >
              {rejectLabel ?? 'Cancel'}
            </button>
          </div>
        </DialogPanel>
      </div>
    </div>
  );
};

const styles = {
  outer: 'fixed inset-0 z-10 w-screen overflow-y-auto',
  inner:
    'flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0',
  panel: {
    container:
      'relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95',
    header: {
      container: 'absolute right-0 top-0 hidden pr-4 pt-4 sm:block',
      btn: {
        container:
          'rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2',
        icon: 'h-6 w-6',
      },
    },
    body: {
      container: 'sm:flex sm:items-start',
      left: {
        container:
          'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10',
        icon: 'h-6 w-6 text-red-600',
      },
      right: {
        container: 'mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left',
        top: 'text-base font-semibold text-gray-900',
        bottom: 'mt-2',
      },
    },
    footer: {
      container: 'mt-5 sm:mt-4 sm:flex sm:flex-row-reverse',
      buttons: {
        left: 'mt-3 inline-flex w-full justify-center rounded-full bg-white px-4 py-2.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto',
        right:
          'inline-flex w-full justify-center rounded-full bg-red-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto',
      },
    },
  },
};

export default ActionableModal;
