import { AuthService } from 'services/AuthService';
import { StateValue } from 'machines/AuthMachine';
import { useActor } from '@xstate/react';
import { User } from 'models/User';
import { Auth, AuthMethodType } from 'models/Auth';

interface UseAuthenticationResult {
  authState: ReturnType<typeof AuthService.getSnapshot>;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  isUnauthenticating: boolean;
  isUnauthenticated: boolean;
  authenticatedUser: User | null;
  authenticationMethods: AuthMethodType[];
  errorMessage: string | undefined;
}

/**
 * This hook wraps the Auth Service with a few convenience helpers. Destructuring allows to
 * keep the consumer's logic minimal.
 */
export const useAuthentication = (): UseAuthenticationResult => {
  const [authState] = useActor(AuthService);

  // State Helpers
  const isAuthenticating = authState.matches(StateValue.Authenticating);
  const isAuthenticated = authState.matches(StateValue.Authenticated);
  const isUnauthenticating = authState.matches(StateValue.Unauthenticating);
  const isUnauthenticated = authState.matches(StateValue.Unauthenticated);

  // Context Helpers

  // There is an issue where the types are not recovered from the context, until we understand WHY,
  // we are forcing the type on the object {} coming from the context to be casted to a `User` and
  // an `Auth` respectively
  const authenticatedUser = authState.context.user
    ? new User(authState.context.user)
    : null;
  const auth = authState.context.auth ? new Auth(authState.context.auth) : null;

  const authenticationMethods = auth?.authMethods;
  const errorMessage = authState.context.errorMessage;

  return {
    authState,
    isAuthenticating,
    isAuthenticated,
    isUnauthenticating,
    isUnauthenticated,
    authenticatedUser,
    authenticationMethods,
    errorMessage,
  };
};
