export const appRoutes = {
  HOME: '/',
  LOGIN: '/login',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  HELP_CENTRE: '/help-centre',
  FACEBOOK_DATA_DELETION: '/facebook-data-deletion',
  MEMORY_BOX: '/memory-box',
  FEED: '/feed',
  // Open Graph
  QUOTES: '/quotes',
  // Settings
  SETTINGS: '/settings',
  PROFILE: '/settings/profile',
  BILLING: '/settings/billing',
  ACCOUNT: '/settings/account',
  NOTIFICATIONS: '/settings/account',
  // Other Pages
  catchAll: '**',
};
