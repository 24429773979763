import type { Collection } from 'models/Collection';
import { HTTPMethod } from 'utils/HTTPUtils';
import { authenticationHeaders } from './firebase';
import { mindfeed as config } from 'config/global';
import { Box } from 'models/Box';

export const COLLECTIONS_URI = `${config.baseUri}/api/collections`;

export const getCollections = async (box?: Box): Promise<Collection[]> => {
  const url = box
    ? `${config.baseUri}/api/boxes/${box.id}/collections`
    : COLLECTIONS_URI;

  const response = await fetch(url, {
    method: HTTPMethod.GET,
    headers: await authenticationHeaders(),
  });

  if (!response.ok) {
    throw new Error(`Unable to fetch collections: error ${response.status}`);
  }

  return response.json() as Promise<Collection[]>;
};

export const saveCollection = async (
  { id, ...restCollectionProperties }: Collection,
  box?: Box
): Promise<Collection> => {
  const body = JSON.stringify({
    id,
    ...(box && box.id ? { boxId: box.id } : {}), // Add boxId property only if box.id exists
    ...restCollectionProperties,
  });

  const response = await fetch(COLLECTIONS_URI, {
    method: id ? HTTPMethod.PUT : HTTPMethod.POST,
    headers: await authenticationHeaders(),
    body,
  });

  if (!response.ok) {
    throw new Error(`Unable to save collection: error ${response.status}`);
  }

  return response.json() as Promise<Collection>;
};

export const deleteCollection = async (id: string): Promise<Collection> => {
  const response = await fetch(`${COLLECTIONS_URI}/${id}`, {
    method: HTTPMethod.DELETE,
    headers: await authenticationHeaders(),
  });

  if (!response.ok) {
    throw new Error(`Unable to delete collection: error ${response.status}`);
  }

  return response.json() as Promise<Collection>;
};
