import { assign, createMachine } from 'xstate';
import { UserCredential } from '@firebase/auth';
import { clear as clearReactQueryClient } from 'services/queryClient';
import { User, fromFirebaseUser as fromFirebaseUserToUser } from 'models/User';
import {
  Auth,
  AuthMethodType,
  fromFirebaseUser as fromFirebaseUserToAuth,
} from 'models/Auth';
import {
  authenticateWithApple,
  authenticateWithFacebook,
  authenticateWithGoogle,
  unauthenticate,
} from 'services/firebase';
import {
  reportIdentity,
  reportUserLoggedInEvent,
  reportUserLoggedOutEvent,
  toSegmentIdentity,
} from 'utils/analytics/segment';
import Router from 'next/router';
import { reset as resetSidebarService } from 'xstate/services/SidebarService';
import { reset as resetSlideOverService } from 'services/SlideOverService';
import { reset as resetBoxService } from 'xstate/services/BoxService';

// State

export enum StateValue {
  CheckingAuthentication = 'CHECKING_AUTHENTICATION',
  Unauthenticated = 'UNAUTHENTICATED',
  Authenticating = 'AUTHENTICATING',
  Authenticated = 'AUTHENTICATED',
  Unauthenticating = 'UNAUTHENTICATING',
}

const AUTHENTICATED = StateValue.Authenticated;
const AUTHENTICATING = StateValue.Authenticating;
const UNAUTHENTICATING = StateValue.Unauthenticating;
const UNAUTHENTICATED = StateValue.Unauthenticated;

type AuthMachineState =
  | {
      value: StateValue.Unauthenticated;
      context: AuthMachineContext;
    }
  | {
      value: StateValue.Authenticating;
      context: AuthMachineContext;
    }
  | {
      value: StateValue.Authenticated;
      context: AuthMachineContext;
    }
  | {
      value: StateValue.Unauthenticating;
      context: AuthMachineContext;
    };

// Events

export enum EventType {
  RequestAuthentication = 'REQUEST_AUTHENTICATION',
  RequestLogout = 'REQUEST_LOGOUT',
  CancelAuthentication = 'CANCEL_AUTHENTICATION',
}

export type AuthMachineEvent =
  | {
      type: EventType.RequestAuthentication;
      loginType: AuthMethodType;
      redirectUrl: string;
    }
  | { type: EventType.RequestLogout }
  | { type: EventType.CancelAuthentication };

// Context

export interface AuthMachineContext {
  auth?: Auth;
  user?: User;
  errorMessage?: string;
}

// State Machine

export const authMachine = createMachine<
  AuthMachineContext,
  AuthMachineEvent,
  AuthMachineState
>(
  {
    predictableActionArguments: true,
    id: 'authentication',
    initial: UNAUTHENTICATED,
    context: {
      auth: undefined,
      user: undefined,
      errorMessage: undefined,
    },
    states: {
      // Logged Out
      [UNAUTHENTICATED]: {
        entry: 'clearContext',
        on: {
          [EventType.RequestAuthentication]: AUTHENTICATING,
        },
      },

      // Logging In
      [AUTHENTICATING]: {
        invoke: {
          src: 'authenticateUser',
          onDone: {
            target: AUTHENTICATED,
            actions: [
              'setUser',
              'setAuth',
              'reportUserIdentity',
              'reportLogin',
            ],
          },
          onError: {
            target: UNAUTHENTICATED,
            actions: 'onError',
          },
        },
        on: {
          [EventType.CancelAuthentication]: UNAUTHENTICATED,
        },
      },

      // Logged In
      [AUTHENTICATED]: {
        on: {
          [EventType.RequestLogout]: [UNAUTHENTICATING],
        },
      },

      // Logging Out
      [UNAUTHENTICATING]: {
        invoke: {
          src: 'performLogout',
          onDone: {
            target: UNAUTHENTICATED,
            actions: [
              'clearContext',
              'clearAllDataFromDevice',
              'resetAppState',
              'reportLogout',
            ],
          },
          onError: {
            target: UNAUTHENTICATED,
            actions: 'onError',
          },
        },
      },
    },
  },

  // Actions are the 'Thank u, next' of the XState world. They represent points in time.
  // We use them for fire-and-forget actions. Actions are great for:
  // * `console.log`
  // * Showing ephemeral error or success messages (toasts)
  // * Navigating between pages
  // * Firing off events to external services or parents of your machine
  //
  // Services are like a 'phase' your machine goes through. They represent a length of time.
  // Use them for processes where you care about the outcome, or you want the process to run for a long time.
  // Services are great for:
  // * API calls
  // * Event listeners (window.addEventListener)
  {
    // Services
    // - When we care about the result of a business logic, we put it in a service.
    // - Services are less flexible than actions, because they demand more from us.
    // - We can't hang them on every hook our machine offers. They must be
    // contained within one state, and they're cancelled when we leave that state.
    services: {
      authenticateUser: async (
        _ctx: AuthMachineContext,
        event: any
      ): Promise<any> => {
        const loginType = event.loginType as AuthMethodType;
        const redirectUrl = event?.redirectUrl;
        let userCredential: UserCredential;

        switch (loginType) {
          // Google
          case AuthMethodType.Google: {
            userCredential = await authenticateWithGoogle()
              .then((credential) => {
                if (redirectUrl) {
                  Router.push(redirectUrl);
                }
                return credential;
              })
              .catch((_error) => {
                throw Error('Could not authenticate with Google');
              });
            break;
          }
          // Facebook
          case AuthMethodType.Facebook: {
            userCredential = await authenticateWithFacebook()
              .then((credential) => {
                if (redirectUrl) {
                  Router.push(redirectUrl);
                }
                return credential;
              })
              .catch((_error) => {
                throw Error('Could not authenticate with Facebook');
              });
            break;
          }
          // Apple
          case AuthMethodType.Apple: {
            userCredential = await authenticateWithApple()
              .then((credential) => {
                if (redirectUrl) {
                  Router.push(redirectUrl);
                }
                return credential;
              })
              .catch((_error) => {
                throw Error('Could not authenticate with Apple');
              });
            break;
          }

          // Unkown
          case AuthMethodType.Unknown: {
            throw Error('Unsupported login type');
          }
        }

        return Promise.resolve({ user: userCredential.user });
      },
      performLogout: async (_ctx, _event) => {
        unauthenticate()
          // eslint-disable-next-line no-console
          .then(() => console.log('User signed out!'))
          .then(() => Router.push('/'));
      },
    },
    // Actions
    // - Actions are fire and forget. They are designed to be forgotten.
    // - We don't care about their outcome. We can hange them on the hooks our
    // state machines gives us:
    // * transitions between states, exiting states and entering states.
    actions: {
      clearAllDataFromDevice: () => {
        // We clear React Query Client cache to prevent next signed in user from seeing
        // previous authenticatd user cached data (such as drops, etc.)
        clearReactQueryClient();

        // We clear the local storage
        localStorage.removeItem(
          process.env.NEXT_PUBLIC_LOCAL_STORAGE_KEY_AUTH_SERVICE_STATE
        );
      },
      resetAppState: () => {
        // Reset Services associated w/ State Machines
        resetSidebarService();
        resetSlideOverService();
        resetBoxService();
      },
      clearContext: assign((_ctx: any, _event: any) => ({
        auth: undefined,
        message: undefined,
        user: undefined,
      })),
      setUser: assign((_ctx: any, event: any) => {
        return {
          user: fromFirebaseUserToUser(event.data.user),
        };
      }),
      setAuth: assign((_ctx: any, event: any) => {
        return {
          auth: fromFirebaseUserToAuth(event.data.user),
        };
      }),
      onError: assign((_ctx: any, event: any) => {
        return {
          errorMessage: event.data.message,
        };
      }),
      // Note. Redirection will NOT work in an `action` but it will work in a `service`
      // So this wouln't work:
      redirectToHomepage: (_ctx: any, _event: any) => {
        // This doesn't work
        //     Error: No router instance found.
        //     You should only use "next/router" on the client side of your app.
        //
        // typeof window !== 'undefined' && Router.push('/')
        // if (isBrowser()) {
        //   Router.push('/');
        // }
      },
      // Analytics
      reportUserIdentity: (_ctx: any, event: any) => {
        const segmentIdentity = toSegmentIdentity(event.data.user);
        reportIdentity(segmentIdentity);
      },
      reportLogin: (_ctx: any, event: any) => {
        const auth = fromFirebaseUserToAuth(event.data.user);
        const authMethod = auth.authMethods.shift();
        if (authMethod) {
          reportUserLoggedInEvent(authMethod);
        }
      },
      reportLogout: () => reportUserLoggedOutEvent(),
    },
  }
);
